import "../style/components/navbar.scss";
import Home from "../icons/home";
import Campaigns from "../icons/campaigns";
import CustomersIcon from "../icons/customers_icon";
import Channels from "../icons/channels";
import Users from "../icons/users";
import Logout from "../icons/logout";
import AdUnit from "../icons/ad_unit";

export default function Navbar({ setView, selected_view, role, logOut }) {
  return (
    <div className="navbar p-3">
      {!!role ? (
        <>
          {["admin", "agent"].includes(role.name) ? (
            <button
              onClick={() => setView("/home")}
              className={`center p-2 ratio-1-1 border-none outline-none mb-3 mx-auto ${
                selected_view === "home" ? "selected" : ""
              }`}
            >
              <Home color="#7E7E7E" size={35} />
              <small>Home</small>
            </button>
          ) : null}

          {["admin", "agent"].includes(role.name) ? (
            <button
              onClick={() => setView("/campaigns")}
              className={`center p-2 ratio-1-1 border-none outline-none mb-3 mx-auto ${
                selected_view === "campaigns" ? "selected" : ""
              }`}
            >
              <Campaigns color="#7E7E7E" size={35} />
              <small>Campagne</small>
            </button>
          ) : null}

          {["admin", "agent"].includes(role.name) ? (
            <button
              onClick={() => setView("/ad-units")}
              className={`center p-2 ratio-1-1 border-none outline-none mb-3 mx-auto ${
                selected_view === "ad-units" ? "selected" : ""
              }`}
            >
              <AdUnit color="#7E7E7E" size={35} />
              <small>Ad units</small>
            </button>
          ) : null}

          {["admin", "agent"].includes(role.name) ? (
            <button
              onClick={() => setView("/customers")}
              className={`center p-2 ratio-1-1 border-none outline-none mb-3 mx-auto ${
                selected_view === "customers" ? "selected" : ""
              }`}
            >
              <CustomersIcon color="#7E7E7E" size={35} />
              <small>Clienti</small>
            </button>
          ) : null}

          {["admin"].includes(role.name) ? (
            <button
              onClick={() => setView("/channels")}
              className={`center p-2 ratio-1-1 border-none outline-none mb-3 mx-auto ${
                selected_view === "channels" ? "selected" : ""
              }`}
            >
              <Channels color="#7E7E7E" size={35} />
              <small>Canali</small>
            </button>
          ) : null}

          {["admin"].includes(role.name) ? (
            <button
              onClick={() => setView("/users")}
              className={`center p-2 ratio-1-1 border-none outline-none mb-3 mx-auto ${
                selected_view === "users" ? "selected" : ""
              }`}
            >
              <Users color="#7E7E7E" size={35} />
              <small>Utenti</small>
            </button>
          ) : null}
        </>
      ) : null}

      <button
        onClick={() => logOut()}
        className="center p-2 ratio-1-1 border-none outline-none mx-auto"
      >
        <Logout color="#7E7E7E" size={35} />
        <small>Logout</small>
      </button>
    </div>
  );
}
