export default function CustomersIcon({ color = null, size = null }) {
  return (
    <svg
      fill="none"
      style={{ width: size, height: size }}
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      viewBox="0 0 36 36"
    >
      <path
        d="M7.40667 11.1502C7.4592 10.3623 8.11366 9.75 8.90334 9.75H27.0825C27.8719 9.75 28.5261 10.3617 28.5791 11.1492L29.8922 30.6492C29.9505 31.5154 29.2637 32.25 28.3956 32.25H7.60336C6.73565 32.25 6.04894 31.516 6.10666 30.6503L7.40667 11.1502Z"
        stroke={!!color ? color : "#000000"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18 17.25L19.2394 20.9742H23.25L20.0054 23.2758L21.2447 27L18 24.6983L14.7553 27L15.9947 23.2758L12.75 20.9742H16.7606L18 17.25Z"
        stroke={!!color ? color : "#000000"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.5 12.75V8.25C13.5 5.76472 15.5147 3.75 18 3.75C20.4853 3.75 22.5 5.76472 22.5 8.25V12.75"
        stroke={!!color ? color : "#000000"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
