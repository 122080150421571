export default function AdUnit({ color = null, size = null }) {
  return (
    <svg
      fill="none"
      style={{ width: size, height: size }}
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      viewBox="0 0 36 36"
    >
      <path
        d="M30.75 4.5H5.25C4.42157 4.5 3.75 5.17157 3.75 6V24H30.75C31.5784 24 32.25 23.3284 32.25 22.5V6C32.25 5.17157 31.5784 4.5 30.75 4.5Z"
        stroke={!!color ? color : "#000000"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.75 24L17.25 31.5V24"
        stroke={!!color ? color : "#000000"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
