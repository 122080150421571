import "../style/components/modal.scss";

export default function Modal({
  closeModal,
  buttonFunction,
  button_text,
  modal_text,
  alternative_content = null,
}) {
  return (
    <div className="modal position-fixed w-100 h-100">
      <div
        style={{ maxWidth: 500 }}
        className="position-absolute w-90 w-md-50 p-3"
      >
        {!!alternative_content ? (
          alternative_content
        ) : (
          <>
            <p className="bold">{modal_text}</p>
            <div className="d-flex mt-5">
              <button
                onClick={() => closeModal()}
                className="ml-auto py-2 px-3 border-none outline-none bold"
              >
                Annulla
              </button>
              <button
                onClick={() => buttonFunction()}
                className="ml-3 py-2 px-3 border-none outline-none bold"
              >
                {button_text}
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
