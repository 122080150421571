import "./index.css";
import App from "./App";
import React from "react";
import { createRoot } from "react-dom/client";
import { Permissions } from "./utils/permissions";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

const router = createBrowserRouter([
  {
    element: <App ROUTES_PERMISSIONS={Permissions} />,
    children: Permissions.map((item) => {
      return { path: item.path, element: item.view };
    }),
  },
]);

createRoot(document.getElementById("root")).render(
  <RouterProvider router={router} />
);
