export default function Edit({ color = null, size = null }) {
  return (
    <svg
      fill="none"
      style={{ minWidth: size, minHeight: size }}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 20 20"
    >
      <path
        d="M11.9445 12.1875L7.94446 8.1875M11.9445 12.1875L6.946 17.1859C6.28735 17.8446 5.48784 18.3646 4.56993 18.5229C3.64311 18.6828 2.49294 18.736 1.94444 18.1875C1.39595 17.639 1.44915 16.4888 1.609 15.562C1.76731 14.6441 2.28735 13.8446 2.946 13.1859L7.94446 8.1875M11.9445 12.1875C11.9445 12.1875 14.9444 9.1875 12.9444 7.1875C10.9444 5.1875 7.94446 8.1875 7.94446 8.1875M1.5 10C1.5 3.5 3.5 1.5 10 1.5C16.5 1.5 18.5 3.5 18.5 10C18.5 16.5 16.5 18.5 10 18.5"
        stroke="#7E7E7E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
