import { useEffect, useState } from "react";
import Modal from "../components/modal";
import ViewHeader from "../components/view_header";
import OrderArrow from "../icons/order_arrow";
import Trash from "../icons/trash";
import "../style/components/table.scss";
import Edit from "../icons/edit";
import Undo from "../icons/undo";
import { useOutletContext } from "react-router-dom";
import Link from "../icons/link";
import OverlayCreate from "../components/overlay_create";

export default function Home() {
  const [context, setContext] = useOutletContext();
  const [list, setList] = useState();
  const [modal, setModal] = useState();
  const [order, setOrder] = useState();

  const page_size = 15;
  const [current_page, setCurrentPage] = useState(0);
  const [total_pages, setTotalPages] = useState();

  const [reverse, setReverse] = useState(false);
  const [active_ad_units, setActiveAdUnits] = useState([]);
  const [channels, setChannels] = useState();
  const [channels_filters, setChannelsFilters] = useState();
  const [status_filters_selected, setStatusFiltersSelected] =
    useState("running");
  const [channels_filters_selected, setChannelsFiltersSelected] = useState();

  useEffect(() => {
    if (!!context.user) {
      (async () => {
        let channels_list = await context.fetchData(
          context.supabase.from("channels").select("id, name")
        );
        if (channels_list) setChannels(channels_list);
        let available_ad_units = await context.fetchData(
          context.supabase
            .from("ad_units")
            .select("id, start_date, end_date, status")
            .in("status", ["active", "ready"])
        );

        if (!!available_ad_units) {
          let ad_units_to_remove = [];
          available_ad_units.map((item) => {
            if (item.status !== "draft") {
              let update;
              let now = new Date();

              if (item?.start_date && item?.end_date) {
                if (
                  item.status === "ready" &&
                  now >= new Date(item?.start_date) &&
                  now <= new Date(item?.end_date)
                ) {
                  update = "active";
                } else if (
                  item.status === "active" &&
                  now > new Date(item?.end_date)
                ) {
                  update = "ended";
                } else if (
                  item.status === "active" &&
                  now < new Date(item?.start_date)
                ) {
                  update = "ready";
                } else if (
                  item.status === "ended" &&
                  now < new Date(item?.end_date_date)
                ) {
                  if (now > new Date(item?.start_date)) update = "active";
                  else update = "ready";
                }
              } else if (item?.start_date) {
                if (
                  item.status === "ready" &&
                  now > new Date(item?.start_date)
                ) {
                  update = "active";
                } else if (
                  item.status === "active" &&
                  now < new Date(item?.start_date)
                ) {
                  update = "ready";
                }
              } else if (item?.end_date) {
                if (
                  item.status === "active" &&
                  now > new Date(item?.end_date)
                ) {
                  update = "ended";
                } else if (
                  item.status === "ended" &&
                  now < new Date(item?.end_date_date)
                ) {
                  update = "active";
                }
              }

              if (update) {
                context.fetchData(
                  context.supabase
                    .from("ad_units")
                    .update({
                      status: update,
                    })
                    .eq("id", item.id)
                );
                if (update === "ended") ad_units_to_remove.push(item.id);
              }
            }
          });

          let updated_ad_units = available_ad_units.filter(
            (x) => !ad_units_to_remove.includes(x.id)
          );
          setActiveAdUnits(updated_ad_units);
        }
      })();
    }
  }, [context.user]);

  useEffect(() => {
    if (!!context.user) {
      (async () => {
        if (!!context.role) {
          const { from, to } = getPagination(current_page, page_size);
          searchConditions(from, to);
        }
      })();
    }
  }, [context.user, order, current_page, reverse]);

  const getPagination = (page, size) => {
    let from;
    from = page > 0 ? size * page : 0;
    let to = page > 0 ? size * (page + 1) - 1 : size - 1;
    return { from, to };
  };

  const deleteRow = async (campaign) => {
    if (!!campaign.delete_date && context.role.name === "admin") {
      let result = await context.fetchData(
        context.supabase.from("campaigns").delete().eq("id", campaign.id)
      );
      if (!!result) {
        let obj = [...list];
        let index = obj.findIndex((x) => x.id === campaign.id);
        obj.splice(index, 1);
        setList(
          obj.sort((a, b) => Number(b.full_stream) - Number(a.full_stream))
        );
        setModal();
      }
    } else {
      let current_date = new Date().toDateString();
      let result = await context.fetchData(
        context.supabase
          .from("campaigns")
          .update({ delete_date: current_date })
          .eq("id", campaign.id)
      );

      if (!!result) {
        let obj = [...list];
        let index = obj.findIndex((x) => x.id === campaign.id);
        if (context.role.name === "admin") {
          obj[index].delete_date = current_date;
        } else {
          obj.splice(index, 1);
        }
        setList(
          obj.sort((a, b) => Number(b.full_stream) - Number(a.full_stream))
        );
        setModal();
      }
    }
  };

  const recoverCampaign = async (campaign) => {
    let result = await context.fetchData(
      context.supabase
        .from("campaigns")
        .update({ delete_date: null })
        .eq("id", campaign.id)
    );

    if (!!result) {
      let obj = [...list];
      let index = obj.findIndex((x) => x.id === campaign.id);
      obj[index].delete_date = null;
      setList(
        obj.sort((a, b) => Number(b.full_stream) - Number(a.full_stream))
      );
    }
  };

  const searchConditions = async (from, to) => {
    const url = new URL(window.location.href);
    const channel_id = url.searchParams.get("channel_id");
    const customer_id = url.searchParams.get("customer_id");
    let rows;
    let text = document.querySelector("#search-text").value;
    let filters = {};

    if (channels_filters_selected)
      filters["channel_id"] = channels_filters_selected;

    if (channel_id) {
      if (context.role.name === "admin") {
        rows = await context.fetchData(
          context.supabase
            .from("campaigns")
            .select("*, channel:channel_id(name, channel_name)", {
              count: "exact",
            })
            .eq("channel_id", parseInt(channel_id))
            .ilike("name", `%${!!text ? text : ""}%`)
            .order(!!order ? order : "id", { ascending: reverse })
            .range(from, to)
        );
      } else if (context.role.name === "agent") {
        rows = await context.fetchData(
          context.supabase
            .from("campaigns")
            .select("*, channel:channel_id(name, channel_name)", {
              count: "exact",
            })
            .eq("channel_id", parseInt(channel_id))
            .ilike("name", `%${!!text ? text : ""}%`)
            .order(!!order ? order : "id", { ascending: reverse })
            .range(from, to)
            .filter("delete_date", "is", "null")
        );
      }
    } else if (customer_id) {
      let customer_ad_units = await context.fetchData(
        context.supabase
          .from("ad_units")
          .select("id")
          .eq("customer_id", parseInt(customer_id))
      );
      if (customer_ad_units) {
        customer_ad_units = customer_ad_units.map((x) => x.id);

        if (context.role.name === "admin") {
          rows = await context.fetchData(
            context.supabase
              .from("campaigns")
              .select("*, channel:channel_id(name, channel_name)", {
                count: "exact",
              })
              .match(filters)
              .containedBy("ad_units_ids", customer_ad_units)
              .ilike("name", `%${!!text ? text : ""}%`)
              .order(!!order ? order : "id", { ascending: reverse })
              .range(from, to)
          );
        } else if (context.role.name === "agent") {
          rows = await context.fetchData(
            context.supabase
              .from("campaigns")
              .select("*, channel:channel_id(name, channel_name)", {
                count: "exact",
              })
              .match(filters)
              .containedBy("ad_units_ids", customer_ad_units)
              .ilike("name", `%${!!text ? text : ""}%`)
              .order(!!order ? order : "id", { ascending: reverse })
              .range(from, to)
              .filter("delete_date", "is", "null")
          );
        }
      }
    } else {
      if (context.role.name === "admin") {
        rows = await context.fetchData(
          context.supabase
            .from("campaigns")
            .select("*, channel:channel_id(name, channel_name)", {
              count: "exact",
            })
            .match(filters)
            .ilike("name", `%${!!text ? text : ""}%`)
            .order(!!order ? order : "id", { ascending: reverse })
            .range(from, to)
        );
      } else if (context.role.name === "agent") {
        rows = await context.fetchData(
          context.supabase
            .from("campaigns")
            .select("*, channel:channel_id(name, channel_name)", {
              count: "exact",
            })
            .match(filters)
            .ilike("name", `%${!!text ? text : ""}%`)
            .order(!!order ? order : "id", { ascending: reverse })
            .range(from, to)
            .filter("delete_date", "is", "null")
        );
      }
    }

    if (!!rows) {
      setTotalPages(Math.ceil(rows.count / page_size));
      if (rows.data) {
        setList(
          rows.data.sort(
            (a, b) => Number(b.full_stream) - Number(a.full_stream)
          )
        );
      } else {
        setTotalPages(0);
        setList();
      }
    }
  };

  const startSearch = async (e) => {
    e.preventDefault();
    setCurrentPage(0);

    const { from, to } = getPagination(0, page_size);
    searchConditions(from, to);
  };

  const setOrderType = (type) => {
    if (type === order) {
      setReverse(!reverse);
    } else {
      setOrder(type);
      setReverse(!reverse);
    }
  };

  useEffect(() => {
    if (context.role) searchConditions(0, page_size);
  }, [context, channels_filters_selected]);

  const loadChannelsFilters = () => {
    if (!channels_filters && channels)
      setChannelsFilters(
        <div className="dropdown-filter position-absolute">
          <span
            className="d-block px-2 py-1"
            onClick={() => setChannelsFiltersSelected()}
          >
            TUTTI
          </span>
          {channels.map((item, index) => {
            return (
              <span
                className="d-block px-2 py-1"
                onClick={() => setChannelsFiltersSelected(item.id)}
                key={index}
              >
                {item.name}
              </span>
            );
          })}
        </div>
      );
    else setChannelsFilters();
  };

  return (
    <>
      <ViewHeader
        setView={context.setView}
        title="Campagne"
        primaryButtonText={"Crea campagna"}
        primaryButtonFunction={
          !!context.role &&
          (context.role.name === "admin" || context.role.name === "agent")
            ? () => context.setView("/campaign-create")
            : null
        }
      />
      <div className="ml-auto center-y mb-3">
        <form onSubmit={(e) => startSearch(e)}>
          <input
            id="search-text"
            type="text"
            placeholder="Ricerca campagna"
            className="p-2 outline-none border-none"
          />
          <button className="p-2 outline-none border-none search-button">
            CERCA
          </button>
        </form>
      </div>
      <table className="table">
        <thead>
          <tr>
            <th>
              <div>
                <span>C-ID</span>
              </div>
            </th>
            <th
              onClick={() => setOrderType("created_at")}
              className="cursor-pointer"
            >
              <div>
                <OrderArrow
                  color={order === "created_at" ? "#9c76ff" : "#3d3d3d"}
                  size={20}
                />
                <span>Creazione</span>
              </div>
            </th>
            <th
              onClick={() =>
                new URL(window.location.href).searchParams.get("channel_id")
                  ? null
                  : loadChannelsFilters()
              }
              className="position-relative cursor-pointer"
            >
              <div>
                <OrderArrow
                  color={channels_filters_selected ? "#9c76ff" : "#3d3d3d"}
                  size={20}
                />
                <span>Canale</span>
                {channels_filters}
              </div>
            </th>
            <th>
              <div>
                <span>
                  Titolo Campagna{" "}
                  <span style={{ color: "#9c76ff" }}>Solo Full Stream</span>
                </span>
              </div>
            </th>
            <th>
              <div>
                <span>AD-Unit</span>
              </div>
            </th>
            <th>
              <div>
                <span>Frequenza</span>
              </div>
            </th>
            <th
              onClick={() => setOrderType("start_date")}
              className="cursor-pointer"
            >
              <div>
                <OrderArrow
                  color={order === "start_date" ? "#9c76ff" : "#3d3d3d"}
                  size={20}
                />
                <span>Start</span>
              </div>
            </th>
            <th>
              <div>
                <span>Stato</span>
              </div>
            </th>
            <th className="cursor-pointer">
              <div>
                <span>Link OBS</span>
              </div>
            </th>
            <th>
              <span>Azioni</span>
            </th>
          </tr>
        </thead>
        <tbody>
          {!!list ? (
            list.map((item, index) => {
              let active_ad_units_count = 0;
              if (active_ad_units) {
                item.ad_units_ids.map((item) => {
                  if (active_ad_units.find((x) => x.id === item))
                    active_ad_units_count++;
                });
              }

              let exist_row = false;
              if (
                !status_filters_selected ||
                (status_filters_selected === "running" &&
                  (active_ad_units_count > 0 || item.full_stream))
              ) {
                if (!exist_row) exist_row = true;

                return (
                  <tr key={index} className={item.delete_date ? "deleted" : ""}>
                    <td>
                      <span>{item.id}</span>
                    </td>
                    <td>
                      <span>
                        {new Date(item.created_at).toLocaleDateString("it-IT")}
                        {" - "}
                        {new Date(item.created_at).toLocaleTimeString("it-IT")}
                      </span>
                    </td>
                    <td>
                      <span>{item.channel.name}</span>
                    </td>
                    <td>
                      <span>
                        {item.name}
                        {item.full_stream ? (
                          <span style={{ color: "#9c76ff" }}>
                            {" "}
                            - Full Stream{" "}
                          </span>
                        ) : null}
                      </span>
                    </td>
                    <td>
                      <span>
                        {!item.full_stream
                          ? active_ad_units_count +
                            "/" +
                            item.ad_units_ids.length
                          : "TUTTE"}
                      </span>
                    </td>
                    <td>
                      <span>{item.interval} min.</span>
                    </td>
                    <td>
                      <span>
                        {item.start_date
                          ? `${new Date(
                              item.start_date
                            ).toLocaleDateString()} - ${new Date(
                              item.start_date
                            ).toLocaleTimeString()}`
                          : null}
                      </span>
                    </td>

                    <td>
                      {item.full_stream ? (
                        <strong style={{ color: "#00ff6d" }}>RUNNING</strong>
                      ) : active_ad_units_count > 0 ? (
                        <strong style={{ color: "#00ff6d" }}>RUNNING</strong>
                      ) : (
                        <strong style={{ color: "#ff6071" }}>EXPIRED</strong>
                      )}
                    </td>

                    <td>
                      {item.full_stream || active_ad_units_count > 0 ? (
                        <div
                          className="cursor-pointer"
                          onClick={() =>
                            setModal(
                              <Modal
                                alternative_content={
                                  <OverlayCreate
                                    campaign={item}
                                    closeModal={() => setModal()}
                                    setMessage={context.setMessage}
                                  />
                                }
                              />
                            )
                          }
                        >
                          <Link size="12" /> <span>COPY</span>
                        </div>
                      ) : null}
                    </td>

                    <td>
                      <div className="action-area">
                        {(!!context.role && context.role.name === "admin") ||
                        (!!context.role &&
                          context.role.name === "agent" &&
                          item.creator === context.internal_user.id) ? (
                          <button
                            title="Modifica Camapagna"
                            onClick={() =>
                              !!item.delete_date
                                ? null
                                : context.setView(`/campaign-create/${item.id}`)
                            }
                            className="ml-3 py-2 px-3 border-none"
                          >
                            <Edit size="20" />
                          </button>
                        ) : null}
                        {!!item.delete_date && context.role.name === "admin" ? (
                          <button
                            title="Recupera Campagna"
                            onClick={() => recoverCampaign(item)}
                            className="ml-3 py-2 px-3 border-none recover-button"
                          >
                            <Undo color="#000000" size="20" />
                          </button>
                        ) : (!!context.role && context.role.name === "admin") ||
                          (!!context.role &&
                            context.role.name === "agent" &&
                            item.creator === context.internal_user.id) ? (
                          <button
                            title="Elimina Campagna"
                            onClick={() =>
                              setModal(
                                <Modal
                                  buttonFunction={() => deleteRow(item)}
                                  closeModal={() => setModal()}
                                  button_text="Elimina"
                                  modal_text={
                                    "Sei sicuro di voler eliminare la seguente Campagna?"
                                  }
                                />
                              )
                            }
                            className="ml-3 py-2 px-3 border-none delete-button"
                          >
                            <Trash color="#000000" size="20" />
                          </button>
                        ) : null}
                      </div>
                    </td>
                  </tr>
                );
              }
            })
          ) : (
            <tr>
              <td colSpan={10}>Nessun risultato trovato</td>
            </tr>
          )}
        </tbody>
      </table>

      {!!total_pages && total_pages > 1
        ? [...Array(total_pages)].map((item, index) => {
            return (
              <button
                disabled={current_page === index}
                onClick={() => setCurrentPage(index)}
                key={index}
              >
                {index + 1}
              </button>
            );
          })
        : null}
      {modal}
    </>
  );
}
