export default function Customer({ color = null, size = null }) {
  return (
    <svg
      fill="#7E7E7E"
      style={{ minWidth: size, minHeight: size }}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path d="M3.19 19.989l-2.19.303 1.594 1.532-.389 2.176 1.949-1.043 1.95 1.043-.389-2.176 1.594-1.532-2.19-.303-.965-1.989-.964 1.989zm8 0l-2.19.303 1.594 1.532-.389 2.176 1.949-1.043 1.95 1.043-.389-2.176 1.594-1.532-2.19-.303-.965-1.989-.964 1.989zm7.691 0l-2.19.303 1.594 1.532-.389 2.176 1.949-1.043 1.95 1.043-.389-2.176 1.594-1.532-2.19-.303-.965-1.989-.964 1.989zm-.129-7.171c-2.436-.562-5.206-1.055-4.108-3.129 3.342-6.313.886-9.689-2.644-9.689-3.599 0-5.996 3.506-2.644 9.689 1.131 2.086-1.725 2.579-4.109 3.129-2.176.502-2.258 1.583-2.251 3.474l.004.708h18l.003-.686c.008-1.904-.066-2.992-2.251-3.496z" />
    </svg>
  );
}
