import { useEffect, useState } from "react";
import Modal from "../components/modal";
import ViewHeader from "../components/view_header";
import OrderArrow from "../icons/order_arrow";
import Trash from "../icons/trash";
import "../style/components/table.scss";
import Edit from "../icons/edit";
import Info from "../icons/info";
import Copy from "../icons/copy";
import Undo from "../icons/undo";
import { useOutletContext } from "react-router-dom";

export default function AdUnits() {
  const [context, setContext] = useOutletContext();
  const [list, setList] = useState();
  const [modal, setModal] = useState();
  const [order, setOrder] = useState();

  const page_size = 15;
  const [current_page, setCurrentPage] = useState(0);
  const [total_pages, setTotalPages] = useState();

  const [reverse, setReverse] = useState(false);

  const status = {
    ready: { color: "#faf56a" },
    active: { color: "#00ff6d" },
    ended: { color: "#ff6071" },
    paused: { color: "#ffba6e" },
    draft: { color: "#ffffff" },
  };

  useEffect(() => {
    if (!!context.user && !!context.role) {
      const { from, to } = getPagination(current_page, page_size);
      searchCondition(from, to);
    }
  }, [context.user, order, current_page, reverse]);

  const searchCondition = async (from, to) => {
    const url = new URL(window.location.href);
    const channel_id = url.searchParams.get("channel_id");
    const customer_id = url.searchParams.get("customer_id");
    let rows;
    let text = document.querySelector("#search-text").value;

    if (channel_id) {
      if (context.role.name === "admin") {
        rows = await context.fetchData(
          context.supabase
            .from("ad_units")
            .select(
              "*, channel:channel_id(name, channel_name), customer:customer_id(name), user_creator:creator(user_id)",
              { count: "exact" }
            )
            .eq("channel_id", parseInt(channel_id))
            .ilike("name", `%${!!text ? text : ""}%`)
            .order(!!order ? order : "id", { ascending: reverse })
            .range(from, to)
        );
      } else if (context.role.name === "agent") {
        rows = await context.fetchData(
          context.supabase
            .from("ad_units")
            .select(
              "*, channel:channel_id(name, channel_name), customer:customer_id(name)",
              {
                count: "exact",
              }
            )
            .eq("channel_id", parseInt(channel_id))
            .ilike("name", `%${!!text ? text : ""}%`)
            .order(!!order ? order : "id", { ascending: reverse })
            .range(from, to)
            .filter("delete_date", "is", "null")
        );
      }
    } else if (customer_id) {
      if (context.role.name === "admin") {
        rows = await context.fetchData(
          context.supabase
            .from("ad_units")
            .select(
              "*, channel:channel_id(name, channel_name), customer:customer_id(name), user_creator:creator(user_id)",
              { count: "exact" }
            )
            .eq("customer_id", parseInt(customer_id))
            .ilike("name", `%${!!text ? text : ""}%`)
            .order(!!order ? order : "id", { ascending: reverse })
            .range(from, to)
        );
      } else if (context.role.name === "agent") {
        rows = await context.fetchData(
          context.supabase
            .from("ad_units")
            .select(
              "*, channel:channel_id(name, channel_name), customer:customer_id(name)",
              {
                count: "exact",
              }
            )
            .eq("customer_id", parseInt(customer_id))
            .ilike("name", `%${!!text ? text : ""}%`)
            .order(!!order ? order : "id", { ascending: reverse })
            .range(from, to)
            .filter("delete_date", "is", "null")
        );
      }
    } else {
      if (context.role.name === "admin") {
        rows = await context.fetchData(
          context.supabase
            .from("ad_units")
            .select(
              "*, channel:channel_id(name, channel_name), customer:customer_id(name), user_creator:creator(user_id)",
              { count: "exact" }
            )
            .ilike("name", `%${!!text ? text : ""}%`)
            .order(!!order ? order : "id", { ascending: reverse })
            .range(from, to)
        );
      } else if (context.role.name === "agent") {
        rows = await context.fetchData(
          context.supabase
            .from("ad_units")
            .select(
              "*, channel:channel_id(name, channel_name), customer:customer_id(name)",
              {
                count: "exact",
              }
            )
            .ilike("name", `%${!!text ? text : ""}%`)
            .order(!!order ? order : "id", { ascending: reverse })
            .range(from, to)
            .filter("delete_date", "is", "null")
        );
      }
    }

    if (!!rows) {
      setTotalPages(Math.ceil(rows.count / page_size));

      if (rows?.data) {
        let ad_units_to_remove = [];
        rows.data.map((item) => {
          if (item.status !== "draft") {
            let update;
            let now = new Date();

            if (item?.start_date && item?.end_date) {
              if (
                item.status === "ready" &&
                now >= new Date(item?.start_date) &&
                now <= new Date(item?.end_date)
              ) {
                update = "active";
              } else if (
                item.status === "active" &&
                now > new Date(item?.end_date)
              ) {
                update = "ended";
              } else if (
                item.status === "active" &&
                now < new Date(item?.start_date)
              ) {
                update = "ready";
              } else if (
                item.status === "ended" &&
                now < new Date(item?.end_date_date)
              ) {
                if (now > new Date(item?.start_date)) update = "active";
                else update = "ready";
              }
            } else if (item?.start_date) {
              if (item.status === "ready" && now > new Date(item?.start_date)) {
                update = "active";
              } else if (
                item.status === "active" &&
                now < new Date(item?.start_date)
              ) {
                update = "ready";
              }
            } else if (item?.end_date) {
              if (item.status === "active" && now > new Date(item?.end_date)) {
                update = "ended";
              } else if (
                item.status === "ended" &&
                now < new Date(item?.end_date_date)
              ) {
                update = "active";
              }
            }

            if (update) {
              context.fetchData(
                context.supabase
                  .from("ad_units")
                  .update({
                    status: update,
                  })
                  .eq("id", item.id)
              );
              if (update === "ended") ad_units_to_remove.push(item.id);
            }
          }
        });

        let updated_ad_units = rows.data.filter(
          (x) => !ad_units_to_remove.includes(x.id)
        );
        setList(updated_ad_units);
      }
    }
  };

  const getPagination = (page, size) => {
    let from;
    from = page > 0 ? size * page : 0;
    let to = page > 0 ? size * (page + 1) - 1 : size - 1;
    return { from, to };
  };

  const deleteRow = async (ad_unit) => {
    if (!!ad_unit.delete_date && context.role.name === "admin") {
      let result = await context.fetchData(
        context.supabase.from("ad_units").delete().eq("id", ad_unit.id)
      );
      await context.fetchData(
        context.supabase
          .from("screenshot")
          .delete()
          .eq("ad_unit_id", ad_unit.id)
      );
      await context.fetchData(
        context.supabase.storage.from("screenshot").remove([`${ad_unit.id}`])
      );

      if (!!result) {
        let obj = [...list];
        let index = obj.findIndex((x) => x.id === ad_unit.id);
        obj.splice(index, 1);
        setList(obj);
        setModal();
      }
    } else {
      let current_date = new Date().toDateString();
      let result = await context.fetchData(
        context.supabase
          .from("ad_units")
          .update({ delete_date: current_date })
          .eq("id", ad_unit.id)
      );

      if (!!result) {
        let obj = [...list];
        let index = obj.findIndex((x) => x.id === ad_unit.id);
        if (context.role.name === "admin") {
          obj[index].delete_date = current_date;
        } else {
          obj.splice(index, 1);
        }
        setList(obj);
        setModal();
      }
    }
  };

  const recoverAdUnit = async (ad_unit) => {
    let result = await context.fetchData(
      context.supabase
        .from("ad_units")
        .update({ delete_date: null })
        .eq("id", ad_unit.id)
    );

    if (!!result) {
      let obj = [...list];
      let index = obj.findIndex((x) => x.id === ad_unit.id);
      obj[index].delete_date = null;
      setList(obj);
    }
  };

  const startSearch = async (e) => {
    e.preventDefault();
    setCurrentPage(0);
    const { from, to } = getPagination(0, page_size);
    searchCondition(from, to);
  };

  const setOrderType = (type) => {
    if (type === order) {
      setReverse(!reverse);
    } else {
      setOrder(type);
      setReverse(!reverse);
    }
  };

  return (
    <>
      <ViewHeader
        setView={context.setView}
        title="Ad units"
        primaryButtonText={"Crea Ad unit"}
        primaryButtonFunction={
          !!context.role &&
          (context.role.name === "admin" || context.role.name === "agent")
            ? () => context.setView("/ad-unit-create")
            : null
        }
      />
      <div className="ml-auto center-y mb-3">
        <form onSubmit={(e) => startSearch(e)}>
          <input
            id="search-text"
            type="text"
            placeholder="Ricerca Ad unit"
            className="p-2 outline-none border-none"
          />
          <button className="p-2 outline-none border-none search-button">
            CERCA
          </button>
        </form>
      </div>
      <table className="table">
        <thead>
          <tr>
            <th onClick={() => setOrderType("id")} className="cursor-pointer">
              <div>
                <OrderArrow
                  color={order === "id" ? "#9c76ff" : "#3d3d3d"}
                  size={20}
                />
                <strong>ID</strong>
              </div>
            </th>
            <th className="cursor-pointer">
              <div>
                <strong>CANALE TWITCH</strong>
              </div>
            </th>
            <th className="cursor-pointer">
              <div>
                <strong>CLIENTE</strong>
              </div>
            </th>
            <th
              onClick={() => setOrderType("created_at")}
              className="cursor-pointer"
            >
              <div>
                <OrderArrow
                  color={order === "created_at" ? "#9c76ff" : "#3d3d3d"}
                  size={20}
                />
                <strong>CREAZIONE</strong>
              </div>
            </th>
            <th onClick={() => setOrderType("name")} className="cursor-pointer">
              <div>
                <OrderArrow
                  color={order === "name" ? "#9c76ff" : "#3d3d3d"}
                  size={20}
                />
                <strong>NOME AD UNIT</strong>
              </div>
            </th>
            <th
              onClick={() => setOrderType("start_date")}
              className="cursor-pointer"
            >
              <div>
                <OrderArrow
                  color={order === "start_date" ? "#9c76ff" : "#3d3d3d"}
                  size={20}
                />
                <strong>INIZIO</strong>
              </div>
            </th>
            <th
              onClick={() => setOrderType("end_date")}
              className="cursor-pointer"
            >
              <div>
                <OrderArrow
                  color={order === "end_date" ? "#9c76ff" : "#3d3d3d"}
                  size={20}
                />
                <strong>FINE</strong>
              </div>
            </th>
            <th
              onClick={() => setOrderType("views")}
              className="cursor-pointer"
            >
              <div>
                <OrderArrow
                  color={order === "views" ? "#9c76ff" : "#3d3d3d"}
                  size={20}
                />
                <strong>VIEWS</strong>
              </div>
            </th>
            <th
              onClick={() => setOrderType("status")}
              className="cursor-pointer"
            >
              <div>
                <OrderArrow
                  color={order === "status" ? "#9c76ff" : "#3d3d3d"}
                  size={20}
                />
                <strong>STATO</strong>
              </div>
            </th>
            <th></th>
          </tr>
        </thead>
        {!!list ? (
          <tbody>
            {list.map((item, index) => {
              return (
                <tr key={index} className={item.delete_date ? "deleted" : ""}>
                  <td>
                    <span>{item.id}</span>
                  </td>
                  <td>
                    <span>{item.channel.name}</span>
                  </td>
                  <td>
                    <span>{item.customer.name}</span>
                  </td>
                  <td>
                    <span>
                      {new Date(item.created_at).toLocaleDateString("it-IT")}
                      {" - "}
                      {new Date(item.created_at).toLocaleTimeString("it-IT")}
                    </span>
                  </td>
                  <td>
                    <span>{item.name}</span>
                  </td>
                  <td>
                    <span>
                      {new Date(item.start_date).toLocaleDateString("it-IT")}
                      {" - "}
                      {new Date(item.start_date).toLocaleTimeString("it-IT")}
                    </span>
                  </td>
                  <td>
                    <span>
                      {new Date(item.end_date).toLocaleDateString("it-IT")}
                      {" - "}
                      {new Date(item.end_date).toLocaleTimeString("it-IT")}
                    </span>
                  </td>
                  <td>
                    <span>{item.views}</span>
                  </td>
                  <td>
                    <span style={{ color: status[item.status].color }}>
                      {item.status.toUpperCase()}
                    </span>
                  </td>
                  <td>
                    <div className="action-area">
                      <button
                        title="Andamento Ad unit"
                        onClick={() =>
                          !item.delete_date
                            ? context.setView(`/ad-unit-info/${item.id}`)
                            : null
                        }
                        className="ml-3 py-2 px-3 border-none"
                      >
                        <Info size="20" color="#7E7E7E" />
                      </button>
                      <button
                        title="Copia Ad unit"
                        onClick={() =>
                          !item.delete_date
                            ? context.setView(
                                `/ad-unit-create/${item.id}?is_copy=true`
                              )
                            : null
                        }
                        className="ml-3 py-2 px-3 border-none"
                      >
                        <Copy color="#000000" size="20" />
                      </button>
                      {(!!context.role && context.role.name === "admin") ||
                      (!!context.role &&
                        context.role.name === "agent" &&
                        item.creator === context.internal_user.id) ? (
                        <button
                          title="Modifica Ad unit"
                          onClick={() =>
                            !item.delete_date
                              ? context.setView(`/ad-unit-create/${item.id}`)
                              : null
                          }
                          className="ml-3 py-2 px-3 border-none"
                        >
                          <Edit color="#000000" size="20" />
                        </button>
                      ) : null}
                      {context.role.name === "admin" && !!item.delete_date ? (
                        <button
                          title="Recupera Ad unit"
                          onClick={() => recoverAdUnit(item)}
                          className="ml-3 py-2 px-3 border-none recover-button"
                        >
                          <Undo size="20" />
                        </button>
                      ) : (!!context.role && context.role.name === "admin") ||
                        (!!context.role &&
                          context.role.name === "agent" &&
                          item.creator === context.internal_user.id) ? (
                        <button
                          title="Elimina Ad unit"
                          onClick={() =>
                            setModal(
                              <Modal
                                buttonFunction={() => deleteRow(item)}
                                closeModal={() => setModal()}
                                button_text="Elimina"
                                modal_text={
                                  "Sei sicuro di voler eliminare la seguente Ad unit?"
                                }
                              />
                            )
                          }
                          className="ml-3 py-2 px-3 border-none delete-button"
                        >
                          <Trash color="#000000" size="20" />
                        </button>
                      ) : null}
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        ) : null}
      </table>

      {!!total_pages && total_pages > 1
        ? [...Array(total_pages)].map((item, index) => {
            return (
              <button
                disabled={current_page === index}
                onClick={() => setCurrentPage(index)}
                key={index}
              >
                {index + 1}
              </button>
            );
          })
        : null}
      {modal}
    </>
  );
}
