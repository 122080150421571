import Message from "../components/message";
import { useOutletContext } from "react-router-dom";

export default function Login() {
  const [context, setContext] = useOutletContext();
  const login = async (e) => {
    e.preventDefault();
    let email = e.target.email.value;
    let password = e.target.password.value;

    if (!!email && !!password) {
      context.fetchData(
        await context.supabase.auth.signInWithPassword({
          email,
          password,
        }),
        true
      );
    } else {
      context.setMessage(
        <Message type="danger" message_text="Devi compilare tutti i campi!" />
      );
    }
  };

  return (
    <div className="w-90 w-md-50 w-lg-25 p-3 mx-auto mt-5">
      <form onSubmit={(e) => login(e)}>
        <label className="mb-2 d-block">Email</label>
        <input
          className="d-block mb-3 w-100 p-3 outline-none border-none"
          type="email"
          name="email"
          placeholder="Inserisci email.."
          required
        />
        <label className="mb-2 d-block">Password</label>
        <input
          className="d-block mb-4 w-100 p-3 outline-none border-none"
          type="password"
          name="password"
          placeholder="Inserisci password.."
          required
        />
        <button className="d-block w-100 p-3 outline-none border-none bold">
          Accedi
        </button>
      </form>
    </div>
  );
}
