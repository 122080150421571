import "../style/components/view_header.scss";
import BackArrow from "../icons/back_arrow";

export default function ViewHeader({
  title,
  primaryButtonText,
  primaryButtonFunction,
  backpage,
  setView,
}) {
  return (
    <div className="page-header mb-4 pb-2 center-row">
      {!!backpage ? (
        <div
          onClick={() => setView(backpage)}
          className="center mr-3 cursor-pointer"
        >
          <BackArrow color="#9c76ff" size="40" />
        </div>
      ) : null}
      <strong className="py-2">Twitch Live Adv</strong>
      <h1 className="mr-auto">&nbsp;- {title}</h1>
      {!!primaryButtonFunction ? (
        <button
          onClick={() => primaryButtonFunction()}
          className="py-2 px-3 border-none ml-auto bold"
        >
          {primaryButtonText}
        </button>
      ) : null}
    </div>
  );
}
