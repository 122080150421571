import Message from "../components/message";
import ViewHeader from "../components/view_header";
import { useOutletContext } from "react-router-dom";

export default function UserCreate() {
  const [context, setContext] = useOutletContext();
  const register = async (e) => {
    e.preventDefault();

    let email = e.target.email.value;
    let password = e.target.password.value;
    let confirm_password = e.target.confirm_password.value;

    if (!!confirm_password && !!password && !!email) {
      if (password === confirm_password) {
        await context.fetchData(
          context.supabase.auth.signUp({
            email: email,
            password: password,
          })
        );

        context.setMessage(
          <Message
            type="success"
            message_text="Utente registrato correttamente!"
          />
        );

        context.setView("/users");
      } else {
        context.setMessage(
          <Message
            type="danger"
            message_text="Le due password non corrispondono!"
          />
        );
      }
    } else {
      context.setMessage(
        <Message type="danger" message_text="Devi compilare tutti i campi!" />
      );
    }
  };

  return (
    <>
      <ViewHeader
        setView={context.setView}
        backpage="/users"
        title="Registra utente"
      />
      <div className="w-90 w-md-50 w-lg-25">
        <form onSubmit={(e) => register(e)}>
          <label className="mb-2 d-block">E-mail nuovo utente</label>
          <input
            className="d-block mb-3 w-100 p-3 outline-none border-none"
            type="email"
            name="email"
            placeholder="Inserisci email.."
            required
          />
          <label className="mb-2 d-block">Password nuovo utente</label>
          <input
            className="d-block mb-3 w-100 p-3 outline-none border-none"
            type="password"
            name="password"
            placeholder="Inserisci password.."
            required
          />
          <label className="mb-2 d-block">Conferma password nuovo utente</label>
          <input
            className="d-block mb-4 w-100 p-3 outline-none border-none"
            type="password"
            name="confirm_password"
            placeholder="Conferma password.."
            required
          />

          <button className="d-block w-100 p-3 outline-none border-none bold">
            Registra utente
          </button>
        </form>
      </div>
    </>
  );
}
