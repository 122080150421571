import { useEffect, useState } from "react";
import Modal from "../components/modal";
import ViewHeader from "../components/view_header";
import Edit from "../icons/edit";
import Trash from "../icons/trash";
import "../style/components/table.scss";
import { Link, useOutletContext } from "react-router-dom";

export default function Customers() {
  const [context, setContext] = useOutletContext();
  const [list, setList] = useState();
  const [modal, setModal] = useState();

  const page_size = 15;
  const [current_page, setCurrentPage] = useState(0);
  const [total_pages, setTotalPages] = useState();
  const [available_ad_units, setAvailableAdUnits] = useState();
  const [campaigns_list, setCampaignsList] = useState();

  useEffect(() => {
    (async () => {
      const { from, to } = getPagination(current_page, page_size);
      let rows = await getData(from, to);
      if (!!rows) {
        setTotalPages(Math.ceil(rows.count / page_size));
        setList(rows.data);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const { from, to } = getPagination(current_page, page_size);
      let rows = await getData(from, to);
      if (!!rows) {
        setTotalPages(Math.ceil(rows.count / page_size));
        setList(rows.data);
      }
    })();
  }, [current_page]);

  const getData = async (from, to) => {
    let text = document.querySelector("#search-text").value;
    let available_ad_units = await context.fetchData(
      context.supabase.from("ad_units").select("id, status, customer_id")
    );
    if (!!available_ad_units) {
      available_ad_units = available_ad_units.reduce((x, y) => {
        (x[y.customer_id] = x[y.customer_id] || []).push(y);
        return x;
      }, {});
      setAvailableAdUnits(available_ad_units);
    }

    let campaigns = await context.fetchData(
      context.supabase
        .from("campaigns")
        .select("id, ad_units_ids, full_stream")
        .filter("delete_date", "is", "null")
    );

    if (campaigns) setCampaignsList(campaigns);

    let result = await context.fetchData(
      context.supabase
        .from("customers")
        .select("*", { count: "exact" })
        .ilike("name", `%${!!text ? text : ""}%`)
        .order("name", { ascending: true })
        .range(from, to)
    );
    return result;
  };

  const deleteRow = async (id) => {
    let result = await context.fetchData(
      context.supabase.from("customers").delete().eq("id", id)
    );
    if (!!result) {
      let obj = [...list];
      let index = obj.findIndex((x) => x.id === id);
      obj.splice(index, 1);
      setList(obj);
      setModal();
    }
  };

  const getPagination = (page, size) => {
    let from;
    from = page > 0 ? size * page : 0;
    let to = page > 0 ? size * (page + 1) - 1 : size - 1;
    return { from, to };
  };

  const startSearch = async (e) => {
    e.preventDefault();
    setCurrentPage(0);
    const { from, to } = getPagination(0, page_size);
    let rows = await getData(from, to);
    if (!!rows) {
      setTotalPages(Math.ceil(rows.count / page_size));
      setList(rows.data);
    }
  };

  return (
    <>
      <ViewHeader
        setView={context.setView}
        title="Clienti"
        primaryButtonText="Crea cliente"
        primaryButtonFunction={() => context.setView("/customers-create")}
      />
      <div className="ml-auto center-y mb-3">
        <form onSubmit={(e) => startSearch(e)}>
          <input
            id="search-text"
            type="text"
            placeholder="Ricerca cliente"
            className="p-2 outline-none border-none"
          />
          <button className="p-2 outline-none border-none search-button">
            CERCA
          </button>
        </form>
      </div>
      <table className="table">
        <thead>
          <tr>
            <th>Nome cliente</th>
            <th>AD-Unit</th>
            <th>Campagne</th>
            {!!context.role && context.role.name === "admin" ? <th></th> : null}
          </tr>
        </thead>
        <tbody>
          {!!list
            ? list.map((item, index) => {
                let active_campaign = 0;
                let campaign_list_count = 0;
                let active_ad_units = 0;
                let active_ad_units_list;

                if (available_ad_units[item.id]) {
                  active_ad_units_list = available_ad_units[item.id].filter(
                    (x) => ["ready", "active"].includes(x.status)
                  );
                  active_ad_units = active_ad_units_list.length;

                  let included_campaigns = campaigns_list.filter((x) =>
                    x.ad_units_ids.some((id) =>
                      available_ad_units[item.id].map((a) => a.id).includes(id)
                    )
                  );

                  campaign_list_count = included_campaigns.length;

                  included_campaigns.map((campaign_item) => {
                    let active = false;
                    campaign_item.ad_units_ids.map((sub_item) => {
                      if (!active) {
                        let check_active_campaign = available_ad_units[
                          item.id
                        ].find(
                          (x) =>
                            x.id === sub_item &&
                            ["active", "ready"].includes(x.status)
                        );
                        if (check_active_campaign) active = true;
                      }
                    });
                    if (active) active_campaign++;
                  });
                }

                return (
                  <tr key={index}>
                    <td>{item.name}</td>
                    <td>
                      <span
                        style={{
                          color: active_ad_units > 0 ? "#00ff6d" : "#ff6071",
                        }}
                      >
                        {active_ad_units}
                      </span>
                      /
                      {available_ad_units[item.id]
                        ? available_ad_units[item.id].length
                        : 0}
                      <Link
                        style={{ color: "#ffffff" }}
                        to={`/ad-units?customer_id=${item.id}`}
                        className="d-block decoration-none mt-2"
                      >
                        Vedi tutte
                      </Link>
                    </td>
                    <td>
                      <span
                        style={{
                          color: active_campaign > 0 ? "#00ff6d" : "#ff6071",
                        }}
                      >
                        {active_campaign}
                      </span>
                      /{campaign_list_count}
                      <Link
                        style={{ color: "#ffffff" }}
                        to={`/campaigns?customer_id=${item.id}`}
                        className="d-block decoration-none mt-2"
                      >
                        Vedi tutte
                      </Link>
                    </td>
                    {!!context.role && context.role.name === "admin" ? (
                      <td>
                        <div className="action-area">
                          <button
                            onClick={() =>
                              context.setView(`/customers-create/${item.id}`)
                            }
                            className="ml-3 py-2 px-3 border-none"
                          >
                            <Edit color="#000000" size="20" />
                          </button>
                          <button
                            onClick={() =>
                              setModal(
                                <Modal
                                  buttonFunction={() => deleteRow(item.id)}
                                  closeModal={() => setModal()}
                                  button_text="Elimina"
                                  modal_text={
                                    "Sei sicuro di voler eliminare il seguente cliente?"
                                  }
                                />
                              )
                            }
                            className="ml-3 py-2 px-3 border-none delete-button"
                          >
                            <Trash color="#000000" size="20" />
                          </button>
                        </div>
                      </td>
                    ) : null}
                  </tr>
                );
              })
            : null}
        </tbody>
      </table>

      {!!total_pages && total_pages > 1
        ? [...Array(total_pages)].map((item, index) => {
            return (
              <button
                disabled={current_page === index}
                onClick={() => setCurrentPage(index)}
                key={index}
              >
                {index + 1}
              </button>
            );
          })
        : null}
      {modal}
    </>
  );
}
