import { useEffect, useState } from "react";
import Message from "../components/message";
import ViewHeader from "../components/view_header";
import { useOutletContext, useParams } from "react-router-dom";

export default function AdminResetPassword() {
  const params = useParams();
  const [context, setContext] = useOutletContext();
  const [update, setUpdate] = useState();

  useEffect(() => {
    (async () => {
      if (params.user_id) {
        const rows = await context.fetchData(
          context.supabase
            .from("users_roles")
            .select("*, role:roles(*)")
            .eq("id", parseInt(params.user_id))
        );
        if (!!rows) setUpdate(rows[0]);
      }
    })();
  }, []);

  const resetPassword = async (e) => {
    e.preventDefault();

    let email = e.target.email.value;
    let password = e.target.password.value;
    let confirm_password = e.target.confirm_password.value;

    if (!!password && !!confirm_password) {
      if (password === confirm_password) {
        await context.fetchData(
          context.supabase.auth.updateUser({
            email: email,
            password: password,
          })
        );

        context.setMessage(
          <Message type="success" message_text="Password aggiornata!" />
        );
        context.setView("/users");
      } else {
        context.setMessage(
          <Message
            type="danger"
            message_text="Le due password non sono uguali!"
          />
        );
      }
    } else {
      context.setMessage(
        <Message type="danger" message_text="Devi compilare tutti i campi!" />
      );
    }
  };

  return (
    <>
      <ViewHeader
        setView={context.setView}
        backpage="/users"
        title="Reimposta password utente"
      />
      <div className="w-90 w-md-50 w-lg-25">
        <form onSubmit={(e) => resetPassword(e)}>
          <label className="mb-2 d-block">E-mail utente</label>
          <input
            defaultValue={!!update ? update.user_email : ""}
            className="d-block mb-3 w-100 p-3 outline-none border-none"
            type="email"
            name="email"
            placeholder="Inserisci email.."
            required
            disabled
          />
          <label className="mb-2 d-block">Nuova password</label>
          <input
            className="d-block mb-3 w-100 p-3 outline-none border-none"
            type="password"
            name="password"
            placeholder="Inserisci nuova password.."
            required
          />
          <label className="mb-2 d-block">Conferma password</label>
          <input
            className="d-block mb-4 w-100 p-3 outline-none border-none"
            type="password"
            name="confirm_password"
            placeholder="Conferma password.."
            required
          />
          <button className="d-block w-100 p-3 outline-none border-none bold">
            Modifica password
          </button>
        </form>
      </div>
    </>
  );
}
