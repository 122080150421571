import { useState } from "react";
import Message from "./message";
import "../style/components/overlay_create.scss";
import Link from "../icons/link";

export default function OverlayCreate({ campaign, closeModal, setMessage }) {
  //const [start_timer, setStartTimer] = useState(false);
  const [end_timer, setEndTimer] = useState(false);

  const createOverlayLink = async (e) => {
    e.preventDefault();
    let url = new URL(`${window.location.origin}/screen-banner/`);
    url.searchParams.set("campaign_id", campaign.id);
    url.searchParams.set("token", campaign.token);

    //let start_date;
    let end_date;

    // if (start_timer && end_timer) {
    //   start_date = e.target.start_date.value;
    //   end_date = e.target.end_date.value;

    //   if (!!start_date && !!end_date) {
    //     if (start_date > end_date) {
    //       return setMessage(
    //         <Message
    //           type="danger"
    //           message_text="La data di inizio non può essere maggiore della data di fine!"
    //         />
    //       );
    //     } else {
    //       url.searchParams.set("start_date", start_date);
    //       url.searchParams.set("end_date", end_date);
    //     }
    //   } else {
    //     return setMessage(
    //       <Message
    //         type="danger"
    //         message_text="Devi selezionare entrambe le date!"
    //       />
    //     );
    //   }
    // } else {
    //   if (start_timer) {
    //     start_date = e.target.start_date.value;
    //     if (!!start_date) url.searchParams.set("start_date", start_date);
    //     else {
    //       return setMessage(
    //         <Message
    //           type="danger"
    //           message_text="Devi selezionare la data di inizio!"
    //         />
    //       );
    //     }
    //   }

    if (end_timer) {
      end_date = e.target.end_date.value;
      if (!!end_date) url.searchParams.set("end_date", end_date);
      else {
        return setMessage(
          <Message
            type="danger"
            message_text="Devi selezionare la data di fine!"
          />
        );
      }
    }
    //}

    let el = document.createElement("input");
    el.value = url;
    el.setAttribute("readonly", "");
    el.style.position = "absolute";
    el.style.left = "-9999px";
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);

    setMessage(<Message type="info" message_text="Link OBS copiato!" />);
    closeModal();
  };

  return (
    <div className="overlay-create">
      <small className="obs-link-title d-block mb-4">
        Preleva link overlay OBS
      </small>
      <strong className="center-x w-100 mb-3">{campaign.name}</strong>
      <form onSubmit={(e) => createOverlayLink(e)}>
        <div className="d-flex mb-4">
          {/* <div className="w-45">
            <div className="center-y">
              <input
                onChange={(e) => setStartTimer(e.target.checked)}
                id="start-timer"
                type="checkbox"
              />
              <label htmlFor="start-timer" className="ml-3 d-block">
                Inizio programmato
              </label>
            </div>
            {start_timer ? (
              <input
                type="datetime-local"
                name="start_date"
                className="d-block mt-3 w-100 p-3 outline-none border-none"
              />
            ) : null}
          </div> */}
          <div className="w-100">
            <div className="center-y">
              <input
                onChange={(e) => setEndTimer(e.target.checked)}
                id="end-timer"
                type="checkbox"
              />
              <label htmlFor="end-timer" className="ml-3 d-block">
                Fine programmata
              </label>
            </div>
            {end_timer ? (
              <input
                type="datetime-local"
                name="end_date"
                className="d-block mt-3 w-100 p-3 outline-none border-none"
              />
            ) : null}
          </div>
        </div>
        <div className="d-flex">
          <button
            type="button"
            onClick={() =>
              ((campaign.ad_units_ids.length > 0 && !campaign.full_stream) ||
                campaign.full_stream) &&
              campaign.channel_id
                ? window.open(
                    `/screen-banner?full_stream=${
                      campaign.full_stream
                    }&channel_id=${
                      campaign.channel_id
                    }&preview=${campaign.ad_units_ids.map((x) => x)}`
                  )
                : null
            }
            className="py-3 mr-3 px-4 outline-none border-none bold generate-preview-button"
          >
            PREVIEW
          </button>
          <button
            type="submit"
            className="py-3 ml-3 px-4 outline-none border-none bold generate-link-button"
          >
            <Link color="#000000" size={20} /> GENERATE LINK STREAM
          </button>
        </div>
      </form>
    </div>
  );
}
