import { useEffect, useState } from "react";
import Message from "../components/message";
import ViewHeader from "../components/view_header";
import { useOutletContext, useParams } from "react-router-dom";

export default function CustomerCreate() {
  const params = useParams();
  const [context, setContext] = useOutletContext();
  const [update, setUpdate] = useState();

  useEffect(() => {
    if (params.customer_id) {
      (async () => {
        const rows = await context.fetchData(
          context.supabase
            .from("customers")
            .select("*")
            .eq("id", parseInt(params.customer_id))
        );
        if (!!rows) setUpdate(rows[0]);
      })();
    }
  }, []);

  const createCustomer = async (e) => {
    e.preventDefault();
    let name = e.target.name.value;

    if (!!name) {
      let result;
      if (!!update) {
        result = await context.fetchData(
          context.supabase
            .from("customers")
            .update({ name: name })
            .eq("id", update.id)
        );
      } else {
        result = await context.fetchData(
          context.supabase.from("customers").insert({ name: name })
        );
      }
      if (!!result) context.setView("/customers");
    } else {
      context.setMessage(
        <Message type="danger" message_text="Devi compilare tutti i campi!" />
      );
    }
  };

  return (
    <>
      <ViewHeader
        setView={context.setView}
        backpage="/customers"
        title="Crea cliente"
      />
      <div className="w-90 w-md-50 w-lg-25">
        <form onSubmit={(e) => createCustomer(e)}>
          <label className="mb-2 d-block">Nome cliente</label>
          <input
            defaultValue={!!update ? update.name : ""}
            className="d-block mb-4 w-100 p-3 outline-none border-none"
            type="text"
            name="name"
            placeholder="Inserisci nome cliente.."
            required
          />

          <button className="d-block w-100 p-3 outline-none border-none bold">
            Salva cliente
          </button>
        </form>
      </div>
    </>
  );
}
