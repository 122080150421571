import AdminResetPassword from "../views/admin_reset_password";
import AdUnitCreate from "../views/ad_unit_create";
import AdUnitInfo from "../views/ad_unit_info";
import Campaigns from "../views/campaigns";
import ChannelCreate from "../views/channel_create";
import Channels from "../views/channels";
import CustomerCreate from "../views/customer_create";
import Customers from "../views/customers";
import Home from "../views/home";
import Login from "../views/login";
import ObsOverlay from "../views/obs_overlay";
import UserCreate from "../views/user_create";
import UserSettings from "../views/user_settings";
import Users from "../views/users";
import AdUnits from "../views/ad_units";
import CampaignCreate from "../views/campaign_create";
import AdUnitPublic from "../views/ad_unit_public";

export const Permissions = [
  { path: "/", view: <Login />, menu_item: null },
  { path: "/home", view: <Home />, menu_item: "home" },
  { path: "/campaigns", view: <Campaigns />, menu_item: "campaigns" },
  {
    path: "/campaign-create",
    view: <CampaignCreate />,
    menu_item: "campaigns",
  },
  {
    path: "/campaign-create/:campaign_id",
    view: <CampaignCreate />,
    menu_item: "campaigns",
  },
  {
    path: "/ad-unit-public/:ad_unit_id",
    view: <AdUnitPublic />,
    menu_item: "ad-units",
  },
  { path: "/ad-units", view: <AdUnits />, menu_item: "ad-units" },
  { path: "/ad-unit-create", view: <AdUnitCreate />, menu_item: "ad-units" },
  {
    path: "/ad-unit-create/:ad_unit_id",
    view: <AdUnitCreate />,
    menu_item: "ad-units",
  },
  {
    path: "/ad-unit-info/:ad_unit_id",
    view: <AdUnitInfo />,
    menu_item: "ad-units",
  },
  { path: "/screen-banner", view: <ObsOverlay />, menu_item: null },
  { path: "/customers", view: <Customers />, menu_item: "customers" },
  {
    path: "/customers-create",
    view: <CustomerCreate />,
    menu_item: "customers",
  },
  {
    path: "/customers-create/:customer_id",
    view: <CustomerCreate />,
    menu_item: "customers",
  },
  { path: "/channels", view: <Channels />, menu_item: "channels" },
  { path: "/channels-create", view: <ChannelCreate />, menu_item: "channels" },
  {
    path: "/channels-create/:channel_id",
    view: <ChannelCreate />,
    menu_item: "channels",
  },
  { path: "/users", view: <Users />, menu_item: "users" },
  { path: "/users-create", view: <UserCreate />, menu_item: "users" },
  {
    path: "/users-settings/:user_id",
    view: <UserSettings />,
    menu_item: "users",
  },
  {
    path: "/admin-reset-password/:user_id",
    view: <AdminResetPassword />,
    menu_item: "users",
  },
];
