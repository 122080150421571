import { useEffect, useState } from "react";
import ViewHeader from "../components/view_header";
import Message from "../components/message";
import "../style/views/ad_unit_create.scss";
import { useOutletContext, useParams } from "react-router-dom";

export default function AdUnitCreate() {
  const params = useParams();
  const [context, setContext] = useOutletContext();
  const [customers, setCustomers] = useState();
  const [channels, setChannels] = useState();
  const [update, setUpdate] = useState();
  const [is_copy, setIsCopy] = useState();
  const [views_limit_selected, setViewsLimitselected] = useState(false);
  const [position, setPosition] = useState();
  const [is_draft, setIsDraft] = useState(false);

  useEffect(() => {
    (async () => {
      setCustomers(
        await context.fetchData(context.supabase.from("customers").select("*"))
      );
      setChannels(
        await context.fetchData(context.supabase.from("channels").select("*"))
      );
    })();
    const url = new URL(window.location.href);
    setIsCopy(!!url.searchParams.get("is_copy"));

    if (params.ad_unit_id) {
      (async () => {
        const rows = await context.fetchData(
          context.supabase
            .from("ad_units")
            .select("*, channel:channel_id(name, channel_name)")
            .eq("id", parseInt(params.ad_unit_id))
        );
        if (!!rows) {
          setIsDraft(rows[0].status === "draft");
          setUpdate(rows[0]);
          setPosition(rows[0].position);
          setViewsLimitselected(!!rows[0].views_limit);
        }
      })();
    }

    const script = document.createElement("script");
    script.src = "https://smtpjs.com/v3/smtp.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const checkStatus = (start_date, end_date) => {
    let now = new Date();
    let status;
    if (start_date && end_date) {
      if (now >= new Date(start_date) && now <= new Date(end_date))
        status = "active";
      if (now < new Date(start_date)) status = "ready";
      if (now > new Date(end_date)) status = "ended";
    } else if (start_date) {
      if (now >= new Date(start_date)) status = "active";
      if (now < new Date(start_date)) status = "ready";
    } else if (end_date) {
      if (now < new Date(end_date)) status = "active";
      else status = "ended";
    }

    return status;
  };

  const createAdUnit = async (e) => {
    e.preventDefault();

    let spacing = {
      top: !!e.target.space_top.value ? e.target.space_top.value : null,
      bottom: !!e.target.space_bottom.value
        ? e.target.space_bottom.value
        : null,
      left: !!e.target.space_left.value ? e.target.space_left.value : null,
      right: !!e.target.space_right.value ? e.target.space_right.value : null,
    };

    let obj = {
      name: e.target.name.value,
      customer_id: parseInt(e.target.customer.value),
      channel_id: parseInt(e.target.channel.value),
      start_date: e.target.start_date.value,
      end_date: e.target.end_date.value,
      message: e.target.message.value,
      creativity_link: e.target.creativity_link.value,
      status: is_draft
        ? "draft"
        : checkStatus(e.target.start_date.value, e.target.end_date.value),
      spacing: spacing,
      position: position,
    };

    let missing_params = Object.values(obj).findIndex((x) => x == null) >= 0;

    if (!missing_params) {
      obj["views_limit"] = views_limit_selected
        ? e.target.views_limit_value.value
        : null;

      let result;

      if (!!update) {
        if (is_copy) {
          if (!!context.internal_user) {
            obj["token"] = Math.random().toString(36).substr(2);
            obj["creator"] = context.internal_user.id;

            result = await context.fetchData(
              context.supabase
                .from("ad_units")
                .insert(obj)
                .select(
                  "*, user_creator:creator(user_email), channel:channel_id(name)"
                )
            );
          }
        } else {
          if (
            (!!context.role && context.role.name === "admin") ||
            (!!context.role &&
              context.role.name === "agent" &&
              update.creator === context.internal_user.id)
          ) {
            result = await context.fetchData(
              context.supabase
                .from("ad_units")
                .update(obj)
                .eq("id", update.id)
                .select(
                  "*, user_creator:creator(user_email), channel:channel_id(name)"
                )
            );
          }
        }
      } else {
        if (!!context.internal_user) {
          obj["token"] = Math.random().toString(36).substr(2);
          obj["creator"] = context.internal_user.id;
          result = await context.fetchData(
            context.supabase
              .from("ad_units")
              .insert(obj)
              .select(
                "*, user_creator:creator(user_email), channel:channel_id(name)"
              )
          );
        }
      }

      if (!!result) {
        /**Need E-mail config**/
        // if (result[0].status === "draft") {
        //   if (update == null) {
        //     await sendEmail(result[0], "draft");
        //   }
        // } else if (result[0].status === "ready") {
        //   if (!!update) {
        //     if (update.status !== "ready") {
        //       await sendEmail(result[0], "ready");
        //     }
        //   } else await sendEmail(result[0], "ready");
        // }

        context.setView("/ad-units");
      }
    } else {
      context.setMessage(
        <Message type="danger" message_text="Devi compilare tutti i campi!" />
      );
    }
  };

  const sendEmail = async (ad_unit_updated, status) => {
    let message;
    let message_to;
    let subject;

    if (status === "draft") {
      message = `L'utente ${ad_unit_updated.user_creator.user_email} ha creato una nuova Ad unit: ${ad_unit_updated.name} con ID:${ad_unit_updated.id} per il seguente canale twitch: ${ad_unit_updated.channel.name}`;
      message_to = "twitchadv@netaddiction.it";
      subject = "Twitch live ADV - Nuova Ad unit";
    } else if (status === "ready") {
      message = `La Ad unit ${ad_unit_updated.name} con ID:${ad_unit_updated.id} è pronta per essere servita nel canale twitch di ${ad_unit_updated.channel.name}!`;
      message_to = [
        ad_unit_updated.user_creator.user_email,
        "twitchadv@netaddiction.it",
      ];
      subject = "Twitch live ADV - Ad unit pronta";
    }

    window.Email.send({
      To: message_to,
      Username: "netaddiction.twitchadv@gmail.com",
      Password: "password",
      From: "twitchadv@netaddiction.it",
      Subject: subject,
      Body: message,
    });
  };

  return (
    <>
      <ViewHeader
        setView={context.setView}
        backpage="/ad-units"
        title="Crea Ad unit"
      />
      <div className="ad_unit_create w-90 w-md-60 w-lg-35">
        <form onSubmit={(e) => createAdUnit(e)}>
          <label className="mb-2 d-block">Cliente</label>
          <select
            defaultValue={update ? update.customer_id : ""}
            name="customer"
            className="d-block mb-3 w-100 p-3 outline-none border-none"
            required
          >
            <option value="">Seleziona cliente..</option>
            {!!customers
              ? customers.map((item, index) => {
                  return (
                    <option
                      value={item.id}
                      key={index}
                      selected={!!update && update.customer_id === item.id}
                    >
                      {item.name}
                    </option>
                  );
                })
              : null}
          </select>
          <label className="mb-2 d-block">Nome Ad unit</label>
          <input
            defaultValue={!!update ? update.name : ""}
            className="d-block mb-3 w-100 p-3 outline-none border-none"
            type="text"
            name="name"
            placeholder="Inserisci nome Ad unit.."
            required
          />
          <label className="mb-2 d-block">Canale</label>
          <select
            defaultValue={update ? update.channel_id : ""}
            name="channel"
            className="d-block mb-3 w-100 p-3 outline-none border-none"
            required
          >
            <option value="">Seleziona canale..</option>
            {!!channels
              ? channels.map((item, index) => {
                  return (
                    <option
                      value={item.id}
                      key={index}
                      selected={
                        !is_copy && !!update && update.channel_id === item.id
                      }
                    >
                      {item.name}
                    </option>
                  );
                })
              : null}
          </select>
          <div className="d-flex mb-3">
            <div className="w-45">
              <label className="mb-2 d-block">Data inizio</label>
              <input
                defaultValue={
                  !!update
                    ? new Date(
                        new Date(update.start_date).setHours(
                          new Date(update.start_date).getHours() + 1
                        )
                      )
                        .toISOString()
                        .replace(/:00.000Z/, "")
                    : ""
                }
                type="datetime-local"
                name="start_date"
                className="p-3 w-100 outline-none border-none"
                required
              />
            </div>
            <div className="w-45 ml-auto">
              <label className="mb-2 d-block">Data fine</label>
              <input
                defaultValue={
                  !!update
                    ? new Date(
                        new Date(update.end_date).setHours(
                          new Date(update.end_date).getHours() + 1
                        )
                      )
                        .toISOString()
                        .replace(/:00.000Z/, "")
                    : ""
                }
                type="datetime-local"
                name="end_date"
                className="p-3 w-100 outline-none border-none"
                required
              />
            </div>
          </div>
          <label className="mb-2 d-block">Messaggio BOT</label>
          <textarea
            defaultValue={!!update ? update.message : ""}
            name="message"
            placeholder="Inserisci il messaggio bot.."
            className="d-block w-100 mr-3 mb-3 p-3 outline-none border-none"
            required
          ></textarea>
          <label className="mb-3 w-100 center-x">
            Spaziatura e posizionamento
          </label>
          <div className="d-flex mb-3">
            <div className="mr-3">
              <input
                placeholder="px da sopra"
                defaultValue={!!update ? update.spacing.top : null}
                type="number"
                name="space_top"
                className="p-3 mb-2 w-35 mx-auto outline-none border-none d-block"
              />
              <div className="d-flex">
                <input
                  placeholder="px da sinistra"
                  defaultValue={!!update ? update.spacing.left : null}
                  type="number"
                  name="space_left"
                  className="p-3 w-35 ml-auto mr-1 outline-none border-none d-inline-block"
                />
                <input
                  placeholder="px da destra"
                  defaultValue={!!update ? update.spacing.right : null}
                  type="number"
                  name="space_right"
                  className="p-3 w-35 mr-auto ml-1 outline-none border-none d-inline-block"
                />
              </div>
              <input
                placeholder="px da sotto"
                defaultValue={!!update ? update.spacing.bottom : null}
                type="number"
                name="space_bottom"
                className="p-3 mt-2 w-35 mx-auto d-block outline-none border-none"
              />
            </div>
            <div className="banner-position position-relative d-flex flex-wrap p-2 mx-auto my-auto">
              <div
                onClick={() => setPosition("top_left")}
                className="w-45 mb-auto p-2"
              >
                <input
                  checked={position === "top_left"}
                  type="radio"
                  name="position"
                  value="top_left"
                  className="d-block"
                  required
                />
              </div>
              <div
                onClick={() => setPosition("top_right")}
                className="w-45 ml-auto mb-auto p-2"
              >
                <input
                  checked={position === "top_right"}
                  type="radio"
                  name="position"
                  value="top_right"
                  className="ml-auto d-block"
                  required
                />
              </div>

              <input
                onClick={() => setPosition("center_center")}
                checked={position === "center_center"}
                type="radio"
                name="position"
                value="center_center"
                required
                className="position-absolute center-input"
              />

              <div
                onClick={() => setPosition("bottom_left")}
                className="w-45 p-2 mt-auto"
              >
                <input
                  checked={position === "bottom_left"}
                  type="radio"
                  name="position"
                  value="bottom_left"
                  className="d-block"
                  required
                />
              </div>
              <div
                onClick={() => setPosition("bottom_right")}
                className="w-45 ml-auto p-2 mt-auto"
              >
                <input
                  checked={position === "bottom_right"}
                  type="radio"
                  name="position"
                  value="bottom_right"
                  className="ml-auto d-block"
                  required
                />
              </div>
            </div>
          </div>
          <label className="mb-2 d-block">Link creatività</label>
          <input
            defaultValue={!!update ? update.creativity_link : ""}
            type="text"
            name="creativity_link"
            placeholder="Inserisci link creatività.."
            className="d-block mb-3 w-100 p-3 outline-none border-none"
          />

          <div className="center-y mb-4">
            <input
              onChange={(e) => setIsDraft(e.target.checked)}
              checked={is_draft}
              id="is_draft"
              type="checkbox"
            />
            <label htmlFor="is_draft" className="ml-3 mr-3 w-100">
              Vuoi mettere la campagna in bozza?
            </label>
          </div>

          <div className="center-y mb-4">
            <input
              onChange={(e) => setViewsLimitselected(e.target.checked)}
              checked={views_limit_selected}
              id="views-limit"
              type="checkbox"
            />
            <label htmlFor="views-limit" className="ml-3 mr-3 w-100">
              Interrompi sopra un certo numero di views
            </label>
            {views_limit_selected ? (
              <input
                defaultValue={!!update.views_limit ? update.views_limit : null}
                type="number"
                name="views_limit_value"
                placeholder="N°"
                className="p-3 w-40 outline-none border-none"
              />
            ) : null}
          </div>

          <button className="d-block w-100 p-3 outline-none border-none bold">
            Salva Ad unit
          </button>
        </form>
      </div>
    </>
  );
}
