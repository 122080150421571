export default function Campaigns({ color = null, size = null }) {
  return (
    <svg
      fill="none"
      style={{ width: size, height: size }}
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      viewBox="0 0 36 36"
    >
      <path
        d="M3.75 5.25C3.75 4.42157 4.42157 3.75 5.25 3.75H30.75C31.5784 3.75 32.25 4.42157 32.25 5.25V30.75C32.25 31.5784 31.5784 32.25 30.75 32.25H5.25C4.42157 32.25 3.75 31.5784 3.75 30.75V5.25Z"
        stroke={!!color ? color : "#000000"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.75 24H12L13.5 27H22.5L24 24H32.25"
        stroke={!!color ? color : "#000000"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
