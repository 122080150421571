import "./style/global.scss";
import { useEffect, useState } from "react";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import { createClient } from "@supabase/supabase-js";
import Navbar from "./components/navbar";
import Message from "./components/message";
import { Permissions } from "./utils/permissions";

export default function App() {
  const location = useLocation();
  const navigate = useNavigate();
  let supabase = createClient(
    process.env.REACT_APP_SUPABASE_URL,
    process.env.REACT_APP_SUPABASE_ANON_KEY
  );

  const [selected_view, setSelectedView] = useState("home");
  const [message, setMessage] = useState();
  const [is_dashboard, setIsDashboard] = useState(false);

  const logOut = async () => {
    await supabase.auth.signOut();
    updateContext({
      user: null,
      role: null,
      internal_user: null,
    });
    navigate("/");
  };

  const fetchData = async (func, is_login = false) => {
    if (!is_login) {
      /**Get / Set - data**/
      let response = await func;
      if (!!response?.error) {
        if (response.error.status === 401) {
          await logOut();
          return null;
        } else {
          setMessage(
            <Message
              type={response.error.status === 400 ? "warning" : "danger"}
              message_text={response.error.message}
            />
          );
          return null;
        }
      } else {
        if (!!response?.count) return response;
        else if (!!response?.data) return response.data;
        else return true;
      }
    } else {
      /**Login**/
      let response = await func;
      if (!!response?.error) {
        setMessage(
          <Message
            type={response.error.status === 400 ? "warning" : "danger"}
            message_text={response.error.message}
          />
        );
      } else {
        checkCurrentSession();
      }
    }
  };

  const [context, setContext] = useState({
    supabase: supabase,
    fetchData: fetchData,
    setView: navigate,
    setMessage: setMessage,
    logOut: () => logOut(),
    user: null,
    role: null,
    internal_user: null,
  });

  const updateContext = (new_context) => {
    setContext((prev_context) => {
      return { ...prev_context, ...new_context };
    });
  };

  useEffect(() => {
    if (location) {
      let current_path = Permissions.find((x) => x.path === location.pathname);
      if (current_path) {
        setSelectedView(current_path.menu_item);
      }
    }
  }, [location]);

  useEffect(() => {
    checkCurrentSession();
  }, []);

  const checkCurrentSession = async () => {
    if (!location.pathname.includes("/screen-banner")) {
      if (!location.pathname.includes("/ad-unit-public")) {
        setIsDashboard(true);
        let session_user = await fetchData(supabase.auth.getUser());
        let user_role;
        if (!!session_user) {
          if (session_user?.user?.id) {
            user_role = await fetchData(
              supabase
                .from("users_roles")
                .select("*, role:roles(*)")
                .eq("user_id", session_user.user.id)
            );
            updateContext({
              user: session_user.user,
              role: user_role[0].role,
              internal_user: user_role[0],
            });
          } else updateContext({ user: session_user.user });
          if (location.pathname === "/" || location.pathname === "/login")
            navigate("/home");
        } else await logOut();
      }
    } else {
      setIsDashboard(false);
    }
  };

  useEffect(() => {
    if (!!message) {
      setTimeout(() => {
        setMessage();
      }, 4000);
    }
  }, [message]);

  return is_dashboard ? (
    <div className="d-flex h-100">
      {!!context.user && !!context.role ? (
        <Navbar
          setView={navigate}
          selected_view={selected_view}
          setSelectedView={setSelectedView}
          role={context.role}
          logOut={logOut}
        />
      ) : null}
      <div className="view w-100 px-3 px-md-5 py-3 overflow-auto">
        <Outlet context={[context, updateContext]} />
      </div>
      {message}
    </div>
  ) : (
    <Outlet context={[context, updateContext]} />
  );
}
