import { useEffect, useState } from "react";
import Message from "../components/message";
import ViewHeader from "../components/view_header";
import "../style/components/table.scss";
import { useOutletContext, useParams } from "react-router-dom";

export default function UserSettings() {
  const params = useParams();
  const [context, setContext] = useOutletContext();
  const [update, setUpdate] = useState();
  const [selected_role, setSelectedRole] = useState();
  const [roles, setRoles] = useState();

  useEffect(() => {
    (async () => {
      setRoles(
        await context.fetchData(context.supabase.from("roles").select("*"))
      );
      if (params.user_id) {
        const rows = await context.fetchData(
          context.supabase
            .from("users_roles")
            .select("*, role:roles(*)")
            .eq("id", parseInt(params.user_id))
        );
        if (!!rows) setUpdate(rows[0]);
      }
    })();
  }, []);

  useEffect(() => {
    if (!!roles && !!update) {
      let current_role = roles.find((x) => x.id === update.role_id);
      if (current_role == null) {
        current_role = "";
      }
      setSelectedRole(current_role);
    }
  }, [roles, update]);

  const saveList = async (e) => {
    e.preventDefault();

    if (!!selected_role) {
      await context.fetchData(
        context.supabase
          .from("users_roles")
          .update({
            role_id: selected_role.id,
          })
          .eq("id", update.id)
      );
      context.setView("/users");
    } else {
      return context.setMessage(
        <Message
          type="warning"
          message_text="Devi attribuire un ruolo all'utente!"
        />
      );
    }
  };

  return (
    <>
      <ViewHeader
        setView={context.setView}
        backpage="/users"
        title="Impostazione utente"
      />
      {!!roles ? (
        <div className="w-90 w-md-50 w-lg-25">
          <form onSubmit={(e) => saveList(e)}>
            <label className="mb-2 d-block">Ruolo</label>
            <select
              value={!!selected_role ? selected_role.id : ""}
              onChange={(e) =>
                setSelectedRole(
                  roles.find((x) => x.id === parseInt(e.target.value))
                )
              }
              className="d-block mb-3 w-100 p-3 outline-none border-none"
              name="role"
            >
              <option value="">Seleziona ruolo..</option>
              {!!roles
                ? roles.map((item, index) => {
                    return (
                      <option value={item.id} key={index}>
                        {item.name}
                      </option>
                    );
                  })
                : null}
            </select>

            <button className="d-block w-100 p-3 outline-none border-none bold">
              Modifica
            </button>
          </form>
        </div>
      ) : null}
    </>
  );
}
