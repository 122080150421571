export default function Undo({ color = null, size = null }) {
  return (
    <svg
      fill="none"
      style={{ minWidth: size, minHeight: size }}
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="14"
      viewBox="0 0 19 14"
    >
      <path
        d="M6.71429 4.57139C12.64 4.03425 16.4514 6.31997 18.1429 11.4285C14.9143 7.83997 10.896 7.17939 6.71429 9.14282V12.5714L1 6.85711L6.71429 1.14282V4.57139Z"
        stroke="#FFB876"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
