export default function Copy({ color = null, size = null }) {
  return (
    <svg
      fill="none"
      style={{ minWidth: size, minHeight: size }}
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
    >
      <path
        d="M8 4.5V3C8 1.89543 8.8954 1 10 1H17C18.1046 1 19 1.89543 19 3M15.5 12H17C18.1046 12 19 11.1046 19 10V6.5M1 17C1 18.1046 1.89543 19 3 19H10C11.1046 19 12 18.1046 12 17V10C12 8.8954 11.1046 8 10 8H3C1.89543 8 1 8.8954 1 10V12"
        stroke="#7E7E7E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
