import { useEffect, useState } from "react";
import ViewHeader from "../components/view_header";
import Customer from "../icons/customer";
import Password from "../icons/password";
import "../style/components/table.scss";
import { useOutletContext } from "react-router-dom";

export default function Users() {
  const [context, setContext] = useOutletContext();
  const [list, setList] = useState();
  const page_size = 15;
  const [current_page, setCurrentPage] = useState(0);
  const [total_pages, setTotalPages] = useState();

  useEffect(() => {
    (async () => {
      const { from, to } = getPagination(current_page, page_size);
      let rows = await getData(from, to);
      if (!!rows) {
        setTotalPages(Math.ceil(rows.count / page_size));
        setList(rows.data);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const { from, to } = getPagination(current_page, page_size);
      let rows = await getData(from, to);
      if (!!rows) {
        setTotalPages(Math.ceil(rows.count / page_size));
        setList(rows.data);
      }
    })();
  }, [current_page]);

  const getData = async (from, to) => {
    let text = document.querySelector("#search-text").value;
    let result = await context.fetchData(
      context.supabase
        .from("users_roles")
        .select("*, role:roles(*)", { count: "exact" })
        .ilike("user_email", `%${!!text ? text : ""}%`)
        .order("user_email", { ascending: true })
        .range(from, to)
    );
    return result;
  };

  const getPagination = (page, size) => {
    let from;
    from = page > 0 ? size * page : 0;
    let to = page > 0 ? size * (page + 1) - 1 : size - 1;
    return { from, to };
  };

  const startSearch = async (e) => {
    e.preventDefault();
    setCurrentPage(0);
    const { from, to } = getPagination(0, page_size);
    let rows = await getData(from, to);
    if (!!rows) {
      setTotalPages(Math.ceil(rows.count / page_size));
      setList(rows.data);
    }
  };

  return (
    <>
      <ViewHeader
        setView={context.setView}
        title="Utenti"
        primaryButtonText="Crea utente"
        primaryButtonFunction={() => context.setView("/users-create")}
      />
      <div className="ml-auto center-y mb-3">
        <form onSubmit={(e) => startSearch(e)}>
          <input
            id="search-text"
            type="text"
            placeholder="Ricerca utente"
            className="p-2 outline-none border-none"
          />
          <button className="p-2 outline-none border-none search-button">
            CERCA
          </button>
        </form>
      </div>
      <table className="table">
        <thead>
          <tr>
            <th>E-mail</th>
            <th>Ruolo</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {!!list
            ? list.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{item.user_email}</td>
                    <td>{!!item.role ? item.role.name : "Non definito"}</td>
                    <td>
                      <div className="action-area">
                        <button
                          onClick={() =>
                            context.setView(`/users-settings/${item.id}`)
                          }
                          className="ml-3 py-2 px-3 border-none"
                        >
                          <Customer color="#000000" size="20" />
                        </button>

                        <button
                          onClick={() =>
                            context.setView(`/admin-reset-password/${item.id}`)
                          }
                          className="ml-3 py-2 px-3 border-none"
                        >
                          <Password color="#000000" size="20" />
                        </button>
                      </div>
                    </td>
                  </tr>
                );
              })
            : null}
        </tbody>
      </table>
      {!!total_pages && total_pages > 1
        ? [...Array(total_pages)].map((item, index) => {
            return (
              <button
                disabled={current_page === index}
                onClick={() => setCurrentPage(index)}
                key={index}
              >
                {index + 1}
              </button>
            );
          })
        : null}
    </>
  );
}
