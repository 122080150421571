import { useEffect, useState } from "react";
import Message from "../components/message";
import ViewHeader from "../components/view_header";
import { useOutletContext, useParams } from "react-router-dom";

export default function ChannelCreate() {
  const params = useParams();
  const [context, setContext] = useOutletContext();
  const [update, setUpdate] = useState();

  useEffect(() => {
    if (params.channel_id) {
      (async () => {
        const rows = await context.fetchData(
          context.supabase
            .from("channels")
            .select("*")
            .eq("id", parseInt(params.channel_id))
        );
        if (!!rows) setUpdate(rows[0]);
      })();
    }
  }, []);

  const createChannel = async (e) => {
    e.preventDefault();

    let name = e.target.name.value;
    let channel_name = e.target.channel_name.value;

    if (!!name) {
      let result;
      if (!!update) {
        result = await context.fetchData(
          context.supabase
            .from("channels")
            .update({
              name: name,
              channel_name: channel_name,
            })
            .eq("id", update.id)
        );
      } else {
        result = await context.fetchData(
          context.supabase.from("channels").insert({
            name: name,
            channel_name: channel_name,
          })
        );
      }

      if (!!result) {
        context.setView("/channels");
      }
    } else {
      context.setMessage(
        <Message type="danger" message_text="Devi compilare tutti i campi!" />
      );
    }
  };

  return (
    <>
      <ViewHeader
        setView={context.setView}
        backpage="/channels"
        title="Crea canale twitch"
      />
      <div className="w-90 w-md-60 w-lg-35">
        <form onSubmit={(e) => createChannel(e)}>
          <label className="mb-2 d-block">Nome esteso</label>
          <input
            defaultValue={!!update ? update.name : ""}
            className="d-block mb-3 w-100 p-3 outline-none border-none"
            type="text"
            name="name"
            placeholder="Inserisci nome canale esteso.."
            required
          />
          <label className="mb-2 d-block">Nome canale twitch</label>
          <input
            defaultValue={!!update ? update.channel_name : ""}
            className="d-block mb-3 w-100 p-3 outline-none border-none"
            type="text"
            name="channel_name"
            placeholder="Inserisci nome canale twitch.."
            required
          />
          <button className="d-block w-100 p-3 outline-none border-none bold">
            Salva canale
          </button>
        </form>
      </div>
    </>
  );
}
