export default function Channels({ color = null, size = null }) {
  return (
    <svg
      fill="none"
      style={{ width: size, height: size }}
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      viewBox="0 0 36 36"
    >
      <path
        d="M31.5 5.25H4.5C4.08579 5.25 3.75 5.58579 3.75 6V22.5C3.75 22.9142 4.08579 23.25 4.5 23.25H31.5C31.9142 23.25 32.25 22.9142 32.25 22.5V6C32.25 5.58579 31.9142 5.25 31.5 5.25Z"
        stroke={!!color ? color : "#000000"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.75 28.5C6.75 28.0858 7.08579 27.75 7.5 27.75H28.5C28.9142 27.75 29.25 28.0858 29.25 28.5V30C29.25 30.4142 28.9142 30.75 28.5 30.75H7.5C7.08579 30.75 6.75 30.4142 6.75 30V28.5Z"
        stroke={!!color ? color : "#000000"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.25 23.25V27.75"
        stroke={!!color ? color : "#000000"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.75 23.25V27.75"
        stroke={!!color ? color : "#000000"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.5 10.5L22.5 14.2499L16.5 18V10.5Z"
        stroke={!!color ? color : "#000000"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
