export default function Users({ color = null, size = null }) {
  return (
    <svg
      fill="none"
      style={{ width: size, height: size }}
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      viewBox="0 0 36 36"
    >
      <path
        d="M7.5 10H3.75"
        stroke={!!color ? color : "#000000"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32.25 10H28.5"
        stroke={!!color ? color : "#000000"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.5 5.5C7.5 4.67157 8.17155 4 9 4H27C27.8285 4 28.5 4.67157 28.5 5.5V14.5C28.5 15.3284 27.8285 16 27 16H9C8.17155 16 7.5 15.3284 7.5 14.5V5.5Z"
        stroke={!!color ? color : "#000000"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18 12.25C19.2426 12.25 20.25 11.2426 20.25 10C20.25 8.75736 19.2426 7.75 18 7.75C16.7574 7.75 15.75 8.75736 15.75 10C15.75 11.2426 16.7574 12.25 18 12.25Z"
        stroke={!!color ? color : "#000000"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18 32.5C23.799 32.5 28.5 27.799 28.5 22H7.5C7.5 27.799 12.201 32.5 18 32.5Z"
        stroke={!!color ? color : "#000000"}
        strokeWidth="2"
        strokeLinejoin="round"
      />
    </svg>
  );
}
