export default function Drag({ color = null, size = null }) {
  return (
    <svg
      fill="none"
      style={{ minWidth: size, minHeight: size, cursor: "pointer" }}
      xmlns="http://www.w3.org/2000/svg"
      width="23"
      height="28"
      viewBox="0 0 23 26"
    >
      <path
        d="M22.0593 18.75H1.48775C1.2779 18.75 1.07666 18.8323 0.928275 18.9788C0.779893 19.1253 0.696533 19.324 0.696533 19.5312L0.696533 21.0938C0.696533 21.301 0.779893 21.4997 0.928275 21.6462C1.07666 21.7927 1.2779 21.875 1.48775 21.875H22.0593C22.2692 21.875 22.4704 21.7927 22.6188 21.6462C22.7672 21.4997 22.8506 21.301 22.8506 21.0938V19.5312C22.8506 19.324 22.7672 19.1253 22.6188 18.9788C22.4704 18.8323 22.2692 18.75 22.0593 18.75ZM22.0593 12.5H1.48775C1.2779 12.5 1.07666 12.5823 0.928275 12.7288C0.779893 12.8753 0.696533 13.074 0.696533 13.2812L0.696533 14.8438C0.696533 15.051 0.779893 15.2497 0.928275 15.3962C1.07666 15.5427 1.2779 15.625 1.48775 15.625H22.0593C22.2692 15.625 22.4704 15.5427 22.6188 15.3962C22.7672 15.2497 22.8506 15.051 22.8506 14.8438V13.2812C22.8506 13.074 22.7672 12.8753 22.6188 12.7288C22.4704 12.5823 22.2692 12.5 22.0593 12.5ZM22.0593 6.25H1.48775C1.2779 6.25 1.07666 6.33231 0.928275 6.47882C0.779893 6.62534 0.696533 6.82405 0.696533 7.03125L0.696533 8.59375C0.696533 8.80095 0.779893 8.99966 0.928275 9.14618C1.07666 9.29269 1.2779 9.375 1.48775 9.375H22.0593C22.2692 9.375 22.4704 9.29269 22.6188 9.14618C22.7672 8.99966 22.8506 8.80095 22.8506 8.59375V7.03125C22.8506 6.82405 22.7672 6.62534 22.6188 6.47882C22.4704 6.33231 22.2692 6.25 22.0593 6.25ZM22.0593 0H1.48775C1.2779 0 1.07666 0.08231 0.928275 0.228823C0.779893 0.375336 0.696533 0.57405 0.696533 0.78125L0.696533 2.34375C0.696533 2.55095 0.779893 2.74966 0.928275 2.89618C1.07666 3.04269 1.2779 3.125 1.48775 3.125H22.0593C22.2692 3.125 22.4704 3.04269 22.6188 2.89618C22.7672 2.74966 22.8506 2.55095 22.8506 2.34375V0.78125C22.8506 0.57405 22.7672 0.375336 22.6188 0.228823C22.4704 0.08231 22.2692 0 22.0593 0Z"
        fill="#7E7E7E"
      />
    </svg>
  );
}
