import { useNavigate } from "react-router-dom";
import Drag from "../icons/drag";
import Edit from "../icons/edit";
import Info from "../icons/info";
import Trash from "../icons/trash";
import "../style/components/ad_unit_row.scss";
import Modal from "./modal";

export default function AdUnitRow({
  setModal,
  ad_unit_info,
  activate_full_stream,
  dissociateAdUnit,
  index,
  dragStart,
  dragEnter,
  drop,
}) {
  const navigate = useNavigate();
  const status = {
    ready: { color: "#faf56a" },
    active: { color: "#00ff6d" },
    ended: { color: "#ff6071" },
    paused: { color: "#ffba6e" },
    draft: { color: "#ffffff" },
  };

  return (
    <div
      className="ad-unit-row d-flex center-y my-2"
      onDragStart={(e) => dragStart(e, index)}
      onDragEnter={(e) => dragEnter(e, index)}
      onDragEnd={drop}
      draggable={!activate_full_stream}
    >
      {!activate_full_stream ? (
        <span className="mr-3">
          <Drag size={30} />
        </span>
      ) : null}
      <div className="d-flex center-y">
        <div>
          <span>{ad_unit_info.id}</span>
        </div>
        <div>
          <span>
            {new Date(ad_unit_info.created_at).toLocaleDateString("it-IT")}
          </span>
        </div>
        <div className="customer">
          <span>{ad_unit_info.customer.name}</span>
        </div>
        <div className="title">
          <span>{ad_unit_info.name}</span>
        </div>
        <div>
          <span>
            {new Date(ad_unit_info.start_date).toLocaleDateString("it-IT")}{" "}
            {new Date(ad_unit_info.start_date).toLocaleTimeString("it-IT")}
          </span>
        </div>
        <div>
          <span>
            {new Date(ad_unit_info.end_date).toLocaleDateString("it-IT")}{" "}
            {new Date(ad_unit_info.end_date).toLocaleTimeString("it-IT")}
          </span>
        </div>
        <div>
          <span>{ad_unit_info.views}</span>
        </div>
        <div>
          <strong style={{ color: status[ad_unit_info.status].color }}>
            {ad_unit_info.status.toUpperCase()}
          </strong>
        </div>
        <div
          style={{ columnGap: "0.5rem", justifyContent: "end" }}
          className="w-100 d-flex center-y"
        >
          <button
            className="py-2 px-3 border-none"
            type="button"
            onClick={() =>
              setModal(
                <Modal
                  buttonFunction={() => {
                    navigate(`/ad-unit-info/${ad_unit_info.id}`);
                    setModal();
                  }}
                  closeModal={() => setModal()}
                  button_text="Conferma"
                  modal_text={
                    "Sei sicuro di voler lasciare la pagina senza salvare?"
                  }
                />
              )
            }
          >
            <Info size="20" color="#7E7E7E" />
          </button>
          <button
            className="py-2 px-3 border-none"
            type="button"
            onClick={() =>
              setModal(
                <Modal
                  buttonFunction={() => {
                    navigate(`/ad-unit-create/${ad_unit_info.id}`);
                    setModal();
                  }}
                  closeModal={() => setModal()}
                  button_text="Conferma"
                  modal_text={
                    "Sei sicuro di voler lasciare la pagina senza salvare?"
                  }
                />
              )
            }
          >
            <Edit size="20" />
          </button>
          {dissociateAdUnit ? (
            !activate_full_stream ? (
              <button
                className="py-2 px-3 border-none"
                onClick={() => dissociateAdUnit(ad_unit_info.id, true)}
              >
                <Trash size="20" />
              </button>
            ) : null
          ) : null}
        </div>
      </div>
    </div>
  );
}
