import { useEffect, useState } from "react";
import ViewHeader from "../components/view_header";
import "../style/views/ad_unit_info.scss";
import Message from "../components/message";
import PanelArrow from "../icons/panel_arrow";
import Trend from "react-trend";
import { useOutletContext, useParams } from "react-router-dom";

export default function AdUnitPublic() {
  const params = useParams();
  const [context, setContext] = useOutletContext();
  const [customer, setCustomer] = useState();
  const [list, setList] = useState();
  const [daily_views, setDailyViews] = useState();
  const [ad_unit, setAdUnit] = useState();

  useEffect(() => {
    const url = new URL(window.location.href);
    const token = url.searchParams.get("token");
    if (params.ad_unit_id && token) {
      (async () => {
        const rows = await context.fetchData(
          context.supabase
            .from("ad_units")
            .select("*, channel:channel_id(name, channel_name)")
            .match({ id: parseInt(params.ad_unit_id), token: token })
        );
        if (!!rows) setAdUnit(rows[0]);
        else context.setView("/");
      })();
    } else context.setView("/");
  }, []);

  useEffect(() => {
    if (!!ad_unit) {
      (async () => {
        let customer = await context.fetchData(
          context.supabase
            .from("customers")
            .select("name")
            .eq("id", ad_unit.customer_id)
        );
        setCustomer(customer[0].name);
        await setScreenList();
      })();
    }
  }, [ad_unit]);

  const setScreenList = async () => {
    let screen = await context.fetchData(
      context.supabase
        .from("screenshot")
        .select("date, hours, views")
        .eq("ad_unit_id", ad_unit.id)
    );
    if (!!screen && screen.length > 0) {
      let groupByKey = (list, key, { omitKey = false }) =>
        list.reduce(
          (hash, { [key]: value, ...rest }) => ({
            ...hash,
            [value]: (hash[value] || []).concat(
              omitKey ? { ...rest } : { [key]: value, ...rest }
            ),
          }),
          {}
        );

      let daily_views_sum = [];
      let screens = groupByKey(screen, "date", { omitKey: true });
      setList(screens);

      Object.entries(screens).map((item, index) => {
        daily_views_sum.push(item[1].reduce((n, { views }) => n + views, 0));
      });
      setDailyViews(daily_views_sum);
    }
  };

  const collapsePanel = (id) => {
    let panel = document.querySelector(`#${id}`);
    let panel_header = document.querySelector(`#${id}_header`);
    if (panel.classList.contains("d-none")) {
      panel.classList.remove("d-none");
      panel_header.classList.add("panel-open");
    } else {
      panel.classList.add("d-none");
      panel_header.classList.remove("panel-open");
    }
  };

  const shareAdUnit = () => {
    let el = document.createElement("input");
    el.value = `${window.location.origin}/ad-unit-info/${ad_unit.id}?token=${ad_unit.token}`;
    el.setAttribute("readonly", "");
    el.style.position = "absolute";
    el.style.left = "-9999px";
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    context.setMessage(
      <Message type="info" message_text="Link condivisibile copiato!" />
    );
  };

  return (
    <div
      style={{
        backgroundColor: "#121212",
        minHeight: "100%",
        maxHeight: "100%",
        overflow: "auto",
      }}
    >
      <div className="p-3">
        <ViewHeader
          setView={context.setView}
          title={`Ad unit: ${ad_unit?.name || ""}`}
        />
      </div>

      {!!ad_unit ? (
        <div className="ad_unit_info w-100 d-flex flex-wrap p-3">
          <div className="w-100 w-md-30">
            <div className="mb-3">
              <label className="mb-2 d-block">Cliente</label>
              <span className="px-3 py-3 d-block">{customer}</span>
            </div>
            <div className="mb-3">
              <label className="mb-2 d-block">Canale</label>
              <span className="px-3 py-3 d-block">
                <a
                  target="_blank"
                  href={`https://www.twitch.tv/${ad_unit.channel.channel_name}`}
                >
                  {ad_unit.channel.name}
                </a>
              </span>
            </div>
            <div className="center-y mb-3">
              <div className="w-45">
                <label className="mb-2 d-block">Data inizio</label>
                <span className="px-3 py-3 d-block">
                  {new Date(ad_unit.start_date).toLocaleDateString("it-IT")}
                  {" - "}
                  {new Date(ad_unit.start_date).toLocaleTimeString("it-IT")}
                </span>
              </div>
              <div className="ml-auto w-45">
                <label className="mb-2 d-block">Data fine</label>
                <span className="px-3 py-3 d-block">
                  {new Date(ad_unit.end_date).toLocaleDateString("it-IT")}
                  {" - "}
                  {new Date(ad_unit.end_date).toLocaleTimeString("it-IT")}
                </span>
              </div>
            </div>
            <div className="mb-3">
              <label className="mb-2 d-block">Messaggio BOT</label>
              <span className="px-3 py-3 d-block">{ad_unit.message}</span>
            </div>
            <div className="mb-3">
              <label className="mb-2 d-block">Link creatività</label>
              <span className="px-3 py-3 d-block">
                <a href={ad_unit.creativity_link} target="_blank">
                  {ad_unit.creativity_link}
                </a>
              </span>
            </div>
            <div className="mb-3">
              <label className="mb-2 d-block">Stato</label>
              <span className="px-3 py-3 d-block">{ad_unit.status}</span>
            </div>
            {!!daily_views && daily_views.length > 1 ? (
              <div className="mb-3 center-x-column">
                <label className="mb-2 d-block">Andamento nei giorni</label>
                <Trend
                  height={50}
                  smooth
                  autoDraw
                  autoDrawDuration={0}
                  autoDrawEasing="ease-out"
                  data={daily_views}
                  gradient={["purple", "#9c76ff"]}
                  radius={0}
                  strokeWidth={2}
                  strokeLinecap={"butt"}
                />
              </div>
            ) : null}
          </div>

          <div className="w-100 w-md-65 ml-auto">
            <strong className="total-views mb-4">
              Views totali: {ad_unit.views}
            </strong>
            {!!list && Object.entries(list).length > 0
              ? Object.entries(list).map((item, index) => {
                  return (
                    <div key={`${item[0]}_${index}`}>
                      <button
                        id={`panel_${index}_header`}
                        onClick={() => collapsePanel(`panel_${index}`)}
                        type="button"
                        className="collapsible border-none outline-none cursor-pointer w-100 p-3 bold mt-3 center-y"
                      >
                        Data: {item[0]}
                        <div className="ml-5">
                          {!!item[1] && item[1].length > 1 ? (
                            <Trend
                              height={50}
                              smooth
                              autoDraw
                              autoDrawDuration={0}
                              autoDrawEasing="ease-out"
                              data={item[1].map((item, index) => {
                                return item.views;
                              })}
                              gradient={["purple", "#9c76ff"]}
                              radius={0}
                              strokeWidth={2}
                              strokeLinecap={"butt"}
                            />
                          ) : null}
                        </div>
                        <span className="ml-auto mr-5">
                          Views:{" "}
                          {!!daily_views && !!daily_views[index]
                            ? daily_views[index]
                            : 0}
                        </span>
                        <div className="arrow-panel">
                          <PanelArrow size={20} color="#9c76ff" />
                        </div>
                      </button>
                      <div
                        id={`panel_${index}`}
                        className="content d-none overflow-hidden p-3"
                      >
                        <div className="w-100 d-flex flex-wrap">
                          {item[1].map((image_name, sub_index) => {
                            return (
                              <a
                                key={`${image_name.hours}_${sub_index}`}
                                target="_blank"
                                rel="noreferrer"
                                className="p-1 w-25 decoration-none"
                                href={`${process.env.REACT_APP_SUPABASE_URL}/storage/v1/object/public/screenshot/${ad_unit.id}/${item[0]}/${image_name.hours}.png`}
                              >
                                <img
                                  loading="lazy"
                                  alt={`screenshot del ${item[0]} alle ${image_name.hours}`}
                                  className="w-100"
                                  src={`${process.env.REACT_APP_SUPABASE_URL}/storage/v1/object/public/screenshot/${ad_unit.id}/${item[0]}/${image_name.hours}.png`}
                                />
                                <span className="center p-2">
                                  <small className="mr-auto d-inline-block">
                                    Views: {image_name.views}
                                  </small>
                                  <small>
                                    Ore: {image_name.hours.replace(/-/g, ":")}
                                  </small>
                                </span>
                              </a>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  );
                })
              : null}
          </div>
        </div>
      ) : null}
    </div>
  );
}
