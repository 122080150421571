export default function Link({ color = null, size = null }) {
  return (
    <svg
      style={{ width: size, height: size }}
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
    >
      <g clipPath="url(#clip0_497_707)">
        <path
          d="M7.65498 4.34511C9.0553 5.74689 9.03608 7.99428 7.66342 9.37457C7.66084 9.37738 7.65779 9.38043 7.65498 9.38324L6.07998 10.9582C4.69084 12.3474 2.43079 12.3472 1.04186 10.9582C-0.347285 9.56933 -0.347285 7.30902 1.04186 5.92011L1.91153 5.05044C2.14215 4.81982 2.53932 4.9731 2.55123 5.29902C2.56642 5.71438 2.6409 6.13168 2.77834 6.53467C2.82489 6.67112 2.79163 6.82206 2.68968 6.92401L2.38295 7.23074C1.72609 7.8876 1.70549 8.95714 2.35588 9.62043C3.01269 10.2902 4.09227 10.2942 4.75412 9.63238L6.32912 8.05761C6.98985 7.39689 6.98708 6.32893 6.32912 5.67097C6.24238 5.58439 6.155 5.51713 6.08675 5.47014C6.03847 5.43698 5.9986 5.393 5.97034 5.3417C5.94207 5.29041 5.92618 5.23321 5.92393 5.17468C5.91465 4.92702 6.0024 4.67181 6.1981 4.47611L6.69156 3.98263C6.82096 3.85323 7.02395 3.83734 7.174 3.94206C7.34583 4.06205 7.50678 4.19692 7.65498 4.34511ZM10.9581 1.04179C9.56921 -0.347168 7.30916 -0.347355 5.92002 1.04179L4.34502 2.61679C4.34221 2.6196 4.33916 2.62264 4.33658 2.62546C2.96394 4.00574 2.9447 6.25314 4.34502 7.65491C4.49321 7.8031 4.65415 7.93796 4.82598 8.05794C4.97603 8.16266 5.17904 8.14675 5.30842 8.01737L5.80187 7.52389C5.99757 7.32819 6.08532 7.07298 6.07604 6.82532C6.0738 6.76679 6.05791 6.70959 6.02964 6.6583C6.00137 6.607 5.96151 6.56302 5.91322 6.52986C5.84497 6.48287 5.7576 6.41561 5.67086 6.32903C5.01289 5.67107 5.01013 4.60311 5.67086 3.94239L7.24586 2.36762C7.90771 1.70577 8.98726 1.70975 9.6441 2.37957C10.2945 3.04286 10.2739 4.1124 9.61703 4.76926L9.3103 5.07599C9.20835 5.17794 9.17509 5.32888 9.22164 5.46533C9.35907 5.86832 9.43356 6.28562 9.44875 6.70098C9.46068 7.0269 9.85782 7.18018 10.0884 6.94956L10.9581 6.07989C12.3473 4.691 12.3473 2.43069 10.9581 1.04179Z"
          fill={!!color ? color : "#7E7E7E"}
        />
      </g>
      <defs>
        <clipPath id="clip0_497_707">
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
