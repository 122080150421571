import { useEffect, useState, useRef } from "react";
import ViewHeader from "../components/view_header";
import Message from "../components/message";
import "../style/views/campaign_create.scss";
import { useOutletContext, useParams } from "react-router-dom";
import AdUnitRow from "../components/ad_unit_row";
import Plus from "../icons/plus";
import Modal from "../components/modal";
import OverlayCreate from "../components/overlay_create";
import Link from "../icons/link";

export default function CampaignCreate() {
  const params = useParams();
  const dragItem = useRef();
  const dragOverItem = useRef();
  const [context, setContext] = useOutletContext();
  const [modal, setModal] = useState();
  const [channels, setChannels] = useState();
  const [update, setUpdate] = useState();
  const [is_copy, setIsCopy] = useState();
  const [dissociated_ad_unit, setDissociatedAdUnit] = useState([]);
  const [associated_ad_unit, setAssociatedAdUnit] = useState([]);
  const [program_start, setProgramStart] = useState(true);
  const [activate_full_stream, setActivateFullStream] = useState(false);

  useEffect(() => {
    (async () => {
      setChannels(
        await context.fetchData(context.supabase.from("channels").select("*"))
      );
    })();
    const url = new URL(window.location.href);
    setIsCopy(!!url.searchParams.get("is_copy"));

    (async () => {
      if (params.campaign_id) {
        const campaign = await context.fetchData(
          context.supabase
            .from("campaigns")
            .select("*, channel:channel_id(name, channel_name)")
            .eq("id", parseInt(params.campaign_id))
        );
        if (!!campaign) {
          setUpdate(campaign[0]);
          updateAdUnitList(
            campaign[0].channel_id,
            campaign[0].ad_units_ids,
            campaign[0].full_stream
          );
        }
      }
    })();
  }, []);

  const updateAdUnitList = async (
    channel_id,
    ad_units_ids = null,
    full_stream = false
  ) => {
    if (channel_id) {
      const active_ad_units = await context.fetchData(
        context.supabase
          .from("ad_units")
          .select("*, customer:customer_id(name)")
          .in("status", ["ready", "active"])
          .eq("channel_id", channel_id)
      );
      if (!full_stream) {
        if (active_ad_units) {
          let old_list = ad_units_ids || update?.ad_units_ids;
          if (old_list) {
            let associated = [];
            let dissociated = [...active_ad_units];

            old_list.map((item) => {
              let find_ad_unit_index = active_ad_units.findIndex(
                (x) => x.id === item
              );
              if (find_ad_unit_index >= 0) {
                associated.push(active_ad_units[find_ad_unit_index]);
                dissociated.splice(find_ad_unit_index, 1);
              }
            });

            setDissociatedAdUnit(dissociated);
            setAssociatedAdUnit(associated);
          } else {
            setDissociatedAdUnit(active_ad_units);
            setAssociatedAdUnit([]);
          }
        }
      } else {
        setAssociatedAdUnit(active_ad_units || []);
        setDissociatedAdUnit([]);
      }
    }
  };

  useEffect(() => {
    if (update) {
      setProgramStart(!!update.start_date);
      setActivateFullStream(update.full_stream);
    }
  }, [update]);

  useEffect(() => {
    if (!program_start) {
      const date_field = document.querySelector("#start_date");
      if (date_field) date_field.value = "";
    }
  }, [program_start]);

  useEffect(() => {
    let channel = document.querySelector("#channel");
    if (channel) updateAdUnitList(channel.value, null, activate_full_stream);
  }, [activate_full_stream]);

  const createCampaign = async (e) => {
    e.preventDefault();
    let obj = {
      name: e.target.name.value,
      channel_id: parseInt(e.target.channel.value),
      interval: parseInt(e.target.interval.value),
      full_stream: activate_full_stream,
      ad_units_ids: !activate_full_stream
        ? associated_ad_unit.map((item) => {
            return item.id;
          })
        : [],
    };

    let missing_params = Object.values(obj).findIndex((x) => x == null) >= 0;
    obj["start_date"] = activate_full_stream
      ? null
      : e.target.start_date.value || null;

    if (!missing_params) {
      let result;

      if (!!update) {
        if (is_copy) {
          if (!!context.internal_user) {
            obj["token"] = Math.random().toString(36).substr(2);
            obj["creator"] = context.internal_user.id;
            result = await context.fetchData(
              context.supabase.from("campaigns").insert(obj)
            );
          }
        } else {
          if (
            (!!context.role && context.role.name === "admin") ||
            (!!context.role &&
              context.role.name === "agent" &&
              update.creator === context.internal_user.id)
          ) {
            result = await context.fetchData(
              context.supabase.from("campaigns").update(obj).eq("id", update.id)
            );
          }
        }
      } else {
        if (!!context.internal_user) {
          obj["token"] = Math.random().toString(36).substr(2);
          obj["creator"] = context.internal_user.id;
          result = await context.fetchData(
            context.supabase.from("campaigns").insert(obj)
          );
        }
      }

      if (!!result) context.setView("/campaigns");
    } else {
      context.setMessage(
        <Message type="danger" message_text="Devi compilare tutti i campi!" />
      );
    }
  };

  const associateAdUnit = (value, dissociate = false) => {
    if (value) {
      if (dissociate) {
        const ad_unit_index = associated_ad_unit.findIndex(
          (x) => x.id === parseInt(value)
        );
        if (ad_unit_index >= 0) {
          setDissociatedAdUnit([
            ...dissociated_ad_unit,
            { ...associated_ad_unit[ad_unit_index] },
          ]);
          let new_associated = [...associated_ad_unit];
          new_associated.splice(ad_unit_index, 1);
          setAssociatedAdUnit(new_associated || []);
        }
      } else {
        const ad_unit_index = dissociated_ad_unit.findIndex(
          (x) => x.id === parseInt(value)
        );

        if (ad_unit_index >= 0) {
          setAssociatedAdUnit([
            ...associated_ad_unit,
            { ...dissociated_ad_unit[ad_unit_index] },
          ]);

          let new_dissociated = [...dissociated_ad_unit];
          new_dissociated.splice(ad_unit_index, 1);
          setDissociatedAdUnit(new_dissociated);
        }
      }
      const select = document.querySelector("#select_ad_unit");
      if (select) select.value = "";
    }
  };

  const dragStart = (e, position) => {
    dragItem.current = position;
  };

  const dragEnter = (e, position) => {
    dragOverItem.current = position;
  };

  const drop = (e) => {
    const copyListItems = [...associated_ad_unit];
    const dragItemContent = copyListItems[dragItem.current];
    copyListItems.splice(dragItem.current, 1);
    copyListItems.splice(dragOverItem.current, 0, dragItemContent);
    dragItem.current = null;
    dragOverItem.current = null;
    setAssociatedAdUnit(copyListItems);
  };

  return (
    <>
      <ViewHeader
        setView={context.setView}
        backpage="/campaigns"
        title="Crea campagna"
      />
      <div className="campaign_create">
        <form onSubmit={(e) => createCampaign(e)}>
          <div style={{ gap: "2rem" }} className="d-flex center-y mb-4">
            <div className="d-flex center-y mr-auto">
              <label className="mr-3">CANALE TWITCH</label>
              <select
                style={{ width: 300 }}
                onChange={(e) =>
                  updateAdUnitList(e.target.value, null, activate_full_stream)
                }
                id="channel"
                name="channel"
                className="w-100 p-3 outline-none border-none"
                required
              >
                <option value="">Seleziona canale..</option>
                {!!channels
                  ? channels.map((item, index) => {
                      return (
                        <option
                          value={item.id}
                          key={index}
                          selected={
                            !is_copy &&
                            !!update &&
                            update.channel_id === item.id
                          }
                        >
                          {item.name}
                        </option>
                      );
                    })
                  : null}
              </select>
            </div>
            <div className="d-flex center-y mr-auto">
              <label className="mr-3">FREQUENZA ROTAZIONE (Minuti)</label>
              <input
                style={{ width: 160 }}
                defaultValue={!!update ? update.interval : ""}
                className="w-100 p-3 outline-none border-none"
                type="number"
                name="interval"
                placeholder="Inserisci minuti"
                required
              />
            </div>
            <div className="d-flex center-y">
              <label className="mr-3" htmlFor="is_full_stream">
                AD-UNIT FULL STREAM
              </label>
              <input
                className="full-stream-checkbox"
                onChange={(e) => setActivateFullStream(e.target.checked)}
                id="is_full_stream"
                name="full_stream"
                checked={activate_full_stream}
                type="checkbox"
              />
            </div>
          </div>
          <div style={{ gap: "2rem" }} className="d-flex center-y mb-4">
            {activate_full_stream ? (
              <p style={{ color: "#ffffff" }} className="w-50 mr-auto">
                <strong style={{ color: "#f17171" }}>ATTENZIONE!</strong> Avendo
                selezionato l’opzione Full Stream verranno pubblicate tutte le
                AD-Unit Attive per questo canale con la frequenza selezionata.
                Nuove AD-Unit verranno aggiunte ed eleiminate automaticamente
                secondo la loro programmazione. L’ordine non è modificabile.
              </p>
            ) : (
              <div className="mr-auto d-flex center-y">
                <label>PROGRAMMA INIZIO</label>
                <div className="d-flex">
                  <input
                    checked={program_start}
                    onChange={() => setProgramStart(!program_start)}
                    className="mx-3"
                    type="checkbox"
                  />
                  <input
                    id="start_date"
                    defaultValue={
                      !!update
                        ? new Date(
                            new Date(update.start_date).setHours(
                              new Date(update.start_date).getHours() + 1
                            )
                          )
                            .toISOString()
                            .replace(/:00.000Z/, "")
                        : ""
                    }
                    className="d-block w-100 p-3 outline-none border-none"
                    type="datetime-local"
                    name="start_date"
                    placeholder="Inserisci minuti"
                    required={program_start}
                    disabled={!program_start}
                  />
                </div>
              </div>
            )}

            <div className="d-flex center-y">
              <label className="mr-3">AGGIUNGI AD-Unit</label>
              <div className="d-flex">
                <select
                  disabled={activate_full_stream}
                  id="select_ad_unit"
                  className="mr-3 w-100 p-3 outline-none border-none"
                >
                  <option value="">Seleziona Ad unit..</option>
                  {!!dissociated_ad_unit
                    ? dissociated_ad_unit.map((item, index) => {
                        return (
                          <option
                            value={item.id}
                            key={index}
                            selected={
                              !is_copy &&
                              !!update &&
                              update.channel_id === item.id
                            }
                          >
                            {item.name}
                          </option>
                        );
                      })
                    : null}
                </select>
                <button
                  className="add-ad-unit-button border-none outline-none"
                  disabled={activate_full_stream}
                  type="button"
                  onClick={() => {
                    const select = document.querySelector("#select_ad_unit");
                    if (select) associateAdUnit(select.value);
                  }}
                >
                  <Plus size="30" />
                </button>
              </div>
            </div>
          </div>

          {associated_ad_unit
            ? associated_ad_unit.map((item, index) => {
                return (
                  <AdUnitRow
                    setModal={setModal}
                    ad_unit_info={item}
                    dissociateAdUnit={associateAdUnit}
                    activate_full_stream={activate_full_stream}
                    index={index}
                    dragStart={dragStart}
                    dragEnter={dragEnter}
                    drop={drop}
                    key={index}
                  />
                );
              })
            : null}
          <div className="d-flex mt-5">
            <div className="d-flex center-y mr-auto">
              <label className="mr-3">TITOLO</label>
              <input
                defaultValue={!!update ? update.name : ""}
                className="p-3 outline-none border-none"
                type="text"
                name="name"
                placeholder="Inserisci nome campagna.."
                required
              />
              {activate_full_stream ? (
                <strong style={{ color: "#9c76ff" }} className="ml-2">
                  - Full Stream
                </strong>
              ) : null}
            </div>

            <div className="d-flex">
              <button
                onClick={() =>
                  ((associated_ad_unit.length > 0 && !activate_full_stream) ||
                    activate_full_stream) &&
                  document.querySelector("#channel") &&
                  document.querySelector("#channel").value
                    ? window.open(
                        `/screen-banner?full_stream=${activate_full_stream}&channel_id=${
                          document.querySelector("#channel").value
                        }&preview=${associated_ad_unit.map((x) => x.id)}`
                      )
                    : context.setMessage(
                        <Message
                          type="danger"
                          message_text="Devi selezionare almeno un canale"
                        />
                      )
                }
                type="button"
                className="py-3 mr-3 px-4 outline-none border-none bold generate-preview-button"
              >
                PREVIEW
              </button>
              <button
                type="submit"
                className="py-3 px-4 outline-none border-none bold save-button"
              >
                SALVA
              </button>
              {update ? (
                <button
                  onClick={() =>
                    setModal(
                      <Modal
                        alternative_content={
                          <OverlayCreate
                            campaign={update}
                            closeModal={() => setModal()}
                            setMessage={context.setMessage}
                          />
                        }
                      />
                    )
                  }
                  type="button"
                  className="py-3 ml-3 px-4 outline-none border-none bold generate-link-button"
                >
                  <Link color="#000000" size={20} /> GENERATE LINK STREAM
                </button>
              ) : null}
            </div>
          </div>
        </form>
      </div>
      {modal}
    </>
  );
}
