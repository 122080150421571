import { useEffect, useState } from "react";
import "../style/views/obs_overlay.scss";
import tmiJS from "tmi.js";
import { useOutletContext } from "react-router-dom";

export default function ObsOverlay() {
  const [context, setContext] = useOutletContext();
  const [twitch_auth, setTwitchAuth] = useState();
  const [tmi, setTmi] = useState();
  const [iframe, setIframe] = useState();
  const [ad_unit, setAdUnit] = useState();
  const [initialize, setIntialize] = useState(false);
  const [first_banner, setFirstBanner] = useState(true);
  const [preview_video, setPreviewVideo] = useState(false);
  const [campaign_info, setCampaignInfo] = useState();
  const url = new URL(window.location.href);

  var timing_interval;

  /**milliseconds**/
  const start_delay = 5000;
  const second_message_delay = 15000;
  /**minutes**/
  const default_banner_delay = 5;

  const eventMethod = window.addEventListener
    ? "addEventListener"
    : "attachEvent";
  const eventer = window[eventMethod];
  const messageEvent = eventMethod === "attachEvent" ? "onmessage" : "message";

  /**PARAMETRI URL**/
  const campaign_id = parseInt(url.searchParams.get("campaign_id"));
  const campaign_token = url.searchParams.get("token");
  const preview = url.searchParams.get("preview");

  const channel_id = url.searchParams.get("channel_id");
  const full_stream = url.searchParams.get("full_stream");

  const end_date = !!url.searchParams.get("end_date")
    ? new Date(url.searchParams.get("end_date"))
    : null;

  useEffect(() => {
    if (campaign_id && campaign_token) {
      (async () => {
        const required_campaign_info = await context.supabase
          .from("campaigns")
          .select("start_date, channel:channel_id(channel_name)")
          .match({ id: campaign_id, token: campaign_token });

        if (!!required_campaign_info && required_campaign_info.data.length > 0)
          setCampaignInfo(required_campaign_info.data[0]);
      })();
    } else if (preview) {
      setPreviewVideo(true);
      startScript();
    }
  }, []);

  useEffect(() => {
    if (campaign_info) {
      startScript();
    }
  }, [campaign_info]);

  useEffect(() => {
    if (!!tmi) startCreativity();
  }, [tmi]);

  useEffect(() => {
    if (!!ad_unit) getStreamData();
  }, [ad_unit]);

  eventer(
    messageEvent,
    async function (e) {
      let key = e.message ? "message" : "data";
      let data = e[key];
      if (!!data && typeof data === "object" && data?.width && data?.height) {
        let creativity = document.getElementById("creativity");
        creativity.style.width = `${data.width}px`;
        creativity.style.height = `${data.height}px`;
      }
    },
    false
  );

  /**CONNESSIONE CLIENT TWITCH**/
  const twitchAuth = async () => {
    if (twitch_auth == null) {
      await fetch(
        `https://id.twitch.tv/oauth2/token?grant_type=client_credentials&client_id=${process.env.REACT_APP_TWITCH_CLIENT_ID}&client_secret=${process.env.REACT_APP_TWITCH_CLIENT_SECRET}`,
        { method: "POST" }
      ).then(async (response) => {
        if (response.ok) {
          return response.json().then((data) => {
            setTwitchAuth(data);
            twitchBotAuth();
          });
        }
      });
    } else {
      twitchBotAuth();
    }
  };

  /**CONNESSIONE BOT AL CANALE TWITCH**/
  const twitchBotAuth = async () => {
    if (tmi == null) {
      const opts = {
        options: {
          debug: true,
        },
        identity: {
          username: process.env.REACT_APP_TWITCH_CLIENT_NAME,
          password: `oauth:${process.env.REACT_APP_TWITCH_CLIENT_OAUTH}`,
        },
        channels: [campaign_info.channel.channel_name],
      };

      let tmi = new tmiJS.Client(opts);
      await tmi.connect();

      tmi.on("message", (channel, tags, message, self) => {
        if (
          !!tags &&
          tags["display-name"] === process.env.REACT_APP_TWITCH_CLIENT_NAME
        ) {
          let bot_activated = document.querySelector("#bot_activated");

          if (!bot_activated.checked) {
            bot_activated.checked = true;
            createScreenshot(channel.substring(1));
          } else {
            bot_activated.checked = false;
          }
        }
      });

      setTmi(tmi);
    } else {
      startCreativity();
    }
  };

  /**AVVIO LA GENERAZIONE DEI BANNER**/
  const startCreativity = async () => {
    if (preview || full_stream === "true") {
      let date = new Date();
      let ad_units;
      if (full_stream === "true") {
        ad_units = await context.supabase
          .from("ad_units")
          .select(
            "id, creativity_link, message, position, spacing, channel:channel_id(channel_name)"
          )
          .neq("creativity_link", "")
          .match({
            channel_id: parseInt(channel_id),
          })
          .filter("delete_date", "is", "null")
          .lte("start_date", date.toISOString())
          .gte("end_date", date.toISOString())
          .order("id", { ascending: false });
      } else {
        if (channel_id) {
          ad_units = await context.supabase
            .from("ad_units")
            .select(
              "id, creativity_link, message, position, spacing, channel:channel_id(channel_name)"
            )
            .in(
              "id",
              preview.split(",").map((x) => parseInt(x))
            )
            .neq("creativity_link", "")
            .match({
              channel_id: parseInt(channel_id),
            })
            .filter("delete_date", "is", "null")
            .lte("start_date", date.toISOString())
            .gte("end_date", date.toISOString());

          if (ad_units?.data) {
            let sorted_obj = [];
            preview.split(",").map((item) => {
              let current = ad_units.data.find((x) => x.id === parseInt(item));
              if (current) sorted_obj.push(current);
            });
            ad_units.data = sorted_obj;
          }
        }
      }

      let selected_ad_unit;
      if (!!ad_units && !!ad_units.data && ad_units.data.length > 0) {
        if (!!document.querySelector("#info").value) {
          let old_index = ad_units.data.findIndex(
            (x) =>
              x.id ===
              JSON.parse(document.querySelector("#info").value).campaign_id
          );

          if (old_index != null && !!ad_units.data[old_index + 1]) {
            selected_ad_unit = ad_units.data[old_index + 1];
          } else {
            selected_ad_unit = ad_units.data[0];
          }
        } else {
          selected_ad_unit = ad_units.data[0];
        }
      }

      setAdUnit(selected_ad_unit);

      setTimeout(function () {
        startCreativity();
      }, (1 * 60 * 1000) / 2);
    } else {
      if (!!document.querySelector(`#banner_container`)) {
        if (!!tmi && !!twitch_auth) {
          /**SELEZIONO LE CAMPAGNE ATTIVE PER IL CANALE**/
          setIframe();
          let date = new Date();
          const campaign_ad_units = await context.supabase
            .from("campaigns")
            .select(
              "start_date, interval, channel_id, full_stream, ad_units_ids"
            )
            .match({ id: campaign_id, token: campaign_token });

          if (!!campaign_ad_units && campaign_ad_units?.data) {
            const start_date = campaign_ad_units.data[0].start_date
              ? new Date(campaign_ad_units.data[0].start_date)
              : null;

            let confirm_start = false;
            if (!start_date || start_date <= new Date()) {
              let ad_units;
              if (campaign_ad_units.data[0].full_stream) {
                ad_units = await context.supabase
                  .from("ad_units")
                  .select(
                    "id, creativity_link, message, position, spacing, channel:channel_id(channel_name)"
                  )
                  .neq("creativity_link", "")
                  .eq({
                    channel_id: campaign_ad_units.data[0].channel_id,
                  })
                  .filter("delete_date", "is", "null")
                  .lte("start_date", date.toISOString())
                  .gte("end_date", date.toISOString())
                  .order("id", { ascending: false });
              } else {
                ad_units = await context.supabase
                  .from("ad_units")
                  .select(
                    "id, creativity_link, message, position, spacing, channel:channel_id(channel_name)"
                  )
                  .neq("creativity_link", "")
                  .in("id", campaign_ad_units.data[0].ad_units_ids)
                  .match({
                    channel_id: campaign_ad_units.data[0].channel_id,
                  })
                  .filter("delete_date", "is", "null")
                  .lte("start_date", date.toISOString())
                  .gte("end_date", date.toISOString());

                if (ad_units?.data && campaign_ad_units?.data) {
                  let sorted_obj = [];
                  campaign_ad_units.data[0].ad_units_ids.map((item) => {
                    let current = ad_units.data.find(
                      (x) => x.id === parseInt(item)
                    );
                    if (current) sorted_obj.push(current);
                  });
                  ad_units.data = sorted_obj;
                }
              }

              let selected_ad_unit;

              if (!!ad_units && !!ad_units.data && ad_units.data.length > 0) {
                if (!!document.querySelector("#info").value) {
                  let old_index = ad_units.data.findIndex(
                    (x) =>
                      x.id ===
                      JSON.parse(document.querySelector("#info").value)
                        .campaign_id
                  );

                  if (old_index != null && !!ad_units.data[old_index + 1]) {
                    selected_ad_unit = ad_units.data[old_index + 1];
                  } else {
                    selected_ad_unit = ad_units.data[0];
                  }
                } else {
                  selected_ad_unit = ad_units.data[0];
                }

                if (first_banner) {
                  confirm_start = true;
                  setTimeout(() => {
                    setAdUnit(selected_ad_unit);
                  }, (!!campaign_ad_units && campaign_ad_units.data[0].interval > 0 ? campaign_ad_units.data[0].interval : default_banner_delay) * 60 * 1000);
                } else {
                  setAdUnit(selected_ad_unit);
                }
              } else {
                setTimeout(() => {
                  startCreativity();
                }, start_delay);
                return;
              }
            }

            setTimeout(function () {
              startCreativity();
            }, (!!campaign_ad_units && campaign_ad_units.data[0].interval > 0
              ? campaign_ad_units.data[0].interval
              : default_banner_delay) *
              60 *
              1000);

            if (first_banner && confirm_start) setFirstBanner(false);
          }
        } else {
          twitchAuth();
        }
      }
    }
  };

  /**SALVATAGGIO SCREENSHOT E VIEWS**/
  const createScreenshot = async (channel_name) => {
    let target_url = `https://screenshot.netaddiction.it/?url=https://www.twitch.tv/${channel_name}`;
    let target_data = await fetch(target_url, { method: "GET" })
      .then((response) => response.json())
      .then(async (result) => {
        if (!!result && result?.image && result?.views) return result;
        return null;
      })
      .catch((error) => console.log("error", error));

    if (!!target_data) {
      let image = await base64ToArrayBuffer(target_data.image);
      let info = JSON.parse(document.querySelector("#info").value);

      if (!!image && !!info) {
        /**SALVO LO SCREENSHOT DELLA LIVE**/
        await context.supabase.storage
          .from("screenshot")
          .upload(`${info.campaign_id}/${info.date}/${info.hours}.png`, image);

        /**SALVO LE VIEWS DELLA LIVE**/
        await context.supabase.from("screenshot").insert([
          {
            views: target_data.views,
            ad_unit_id: info.campaign_id,
            date: info.date,
            hours: info.hours,
          },
        ]);
      }
    }
    setAdUnit();
  };

  /**CONVERSIONE BASE64 TO BYTE ARRAY**/
  const base64ToArrayBuffer = async (base64) => {
    let binary_string = window.atob(base64);
    let len = binary_string.length;
    let bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
  };

  /**CONTROLLO SE LA LIVE È ATTIVA**/
  const getStreamData = async () => {
    if (preview || full_stream === "true") {
      /**AVVIO LA CREATIVITÀ**/
      document.querySelector("#info").value = JSON.stringify({
        campaign_id: ad_unit.id,
        date: new Date().toLocaleDateString("it-IT").replace(/\//g, "-"),
        hours: new Date().toLocaleTimeString("it-IT").replace(/:/g, "-"),
      });
      setIframe(
        <iframe
          title="creativity"
          id="creativity"
          src={ad_unit.creativity_link}
          frameBorder="0"
          style={{
            marginTop: !!ad_unit.spacing.top
              ? parseInt(ad_unit.spacing.top)
              : 0,
            marginBottom: !!ad_unit.spacing.bottom
              ? parseInt(ad_unit.spacing.bottom)
              : 0,
            marginLeft: !!ad_unit.spacing.left
              ? parseInt(ad_unit.spacing.left)
              : 0,
            marginRight: !!ad_unit.spacing.right
              ? parseInt(ad_unit.spacing.right)
              : 0,
          }}
          className={`position-absolute ${ad_unit.position}`}
        />
      );
    } else {
      let stream = await fetch(
        `https://api.twitch.tv/helix/streams?user_login=${ad_unit.channel.channel_name}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${twitch_auth.access_token}`,
            "Client-Id": process.env.REACT_APP_TWITCH_CLIENT_ID,
          },
        }
      ).then((response) => {
        if (response.ok) {
          return response.json().then((data) => {
            return data;
          });
        } else {
          if (response.status === 400) {
            setTwitchAuth();
            return null;
          }
        }
      });
      /**CONTROLLO SE LA LIVE È ATTIVA**/
      if (!!stream && stream?.data && stream?.data?.length > 0) {
        /**AVVIO LA CREATIVITÀ**/
        document.querySelector("#info").value = JSON.stringify({
          campaign_id: ad_unit.id,
          date: new Date().toLocaleDateString("it-IT").replace(/\//g, "-"),
          hours: new Date().toLocaleTimeString("it-IT").replace(/:/g, "-"),
        });
        setIframe(
          <iframe
            title="creativity"
            id="creativity"
            src={ad_unit.creativity_link}
            frameBorder="0"
            style={{
              marginTop: !!ad_unit.spacing.top
                ? parseInt(ad_unit.spacing.top)
                : 0,
              marginBottom: !!ad_unit.spacing.bottom
                ? parseInt(ad_unit.spacing.bottom)
                : 0,
              marginLeft: !!ad_unit.spacing.left
                ? parseInt(ad_unit.spacing.left)
                : 0,
              marginRight: !!ad_unit.spacing.right
                ? parseInt(ad_unit.spacing.right)
                : 0,
            }}
            className={`position-absolute ${ad_unit.position}`}
          />
        );

        /*primo messaggio per collegare il bot*/
        tmi.say(ad_unit.channel.channel_name, `[#ADV] ${ad_unit.message}`);
        /*secondo messaggio per scatenare evento del bot*/
        setTimeout(() => {
          tmi.say(ad_unit.channel.channel_name, `[#ADV] ${ad_unit.message}`);
        }, second_message_delay);
      }
    }
  };

  /**SCRIPT START**/
  const startScript = async () => {
    if (preview || full_stream === "true") {
      startCreativity();
    } else {
      const start_date = campaign_info.start_date
        ? new Date(campaign_info.start_date)
        : null;

      if (!!start_date && !!end_date) {
        /**PARTENZA E FINE SU ORARIO DEFINITO**/

        timing_interval = setInterval(() => {
          if (new Date() >= start_date && new Date() <= end_date) {
            if (!initialize) {
              setIntialize(true);
              twitchAuth();
            }
          } else {
            if (initialize) {
              setIntialize(false);
              document.querySelector(`#banner_container`).remove();
              clearInterval(timing_interval);
            }
          }
        }, start_delay);
      } else if (!!start_date && end_date == null) {
        /**PARTENZA SU ORARIO DEFINITO**/

        timing_interval = setInterval((e) => {
          if (new Date() >= start_date) {
            if (!initialize) {
              setIntialize(true);
              twitchAuth();
              clearInterval(timing_interval);
            }
          }
        }, start_delay);
      } else if (start_date == null && !!end_date) {
        /**FINE SU ORARIO DEFINITO**/

        timing_interval = setInterval(() => {
          if (!initialize) {
            setIntialize(true);
            twitchAuth();
          }
          if (new Date() >= end_date) {
            if (initialize) {
              setIntialize(false);
              document.querySelector(`#banner_container`).remove();
              clearInterval(timing_interval);
            }
          }
        }, start_delay);
      } else {
        /**PARTENZA AUTOMATICA**/
        setTimeout(() => {
          twitchAuth();
        }, start_delay);
      }
    }
  };

  return (
    <>
      {preview_video ? (
        <div className="video-container">
          <iframe
            src={
              "https://www.youtube.com/embed/0rXeIb9hl5E?controls=0&autoplay=1&mute=1&playsinline=1&loop=1&playlist=0rXeIb9hl5E&start=001"
            }
          />
        </div>
      ) : null}
      <textarea id="info" className="d-none"></textarea>
      <input
        type="checkbox"
        defaultChecked={false}
        id="bot_activated"
        className="d-none"
      />
      <div id="banner_container" className="position-fixed w-100 h-100">
        {iframe}
      </div>
    </>
  );
}
